import React from "react";
import { Calendar } from "antd";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";

// COMPONENTS
import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";

// CONTEXT
import { useAudits } from "../../context/Audit";

// UTILS
import { getNextDate } from "../../utils/Date";

export default function CalendarPage() {
	const { audits, auditsLoading, auditsError } = useAudits();
	const navigate = useNavigate();
	const theme = useTheme();
	const colors = tokens(theme.palette);

	if (auditsLoading) return <LoadingScreen />;
	if (auditsError) return <ErrorScreen />;

	const createAuditEvent = (audit, titlePrefix, date, color) => {
		return {
			id: audit._id,
			title: titlePrefix + audit.auditName,
			date: date,
			description:
				"Audit: " +
				audit.auditName +
				"Kunde: " +
				audit.auditClient.clientName,
			backgroundColor: color,
		};
	};

	const getAllCarriedOutAudits = () =>
		audits.map((audit) =>
			createAuditEvent(
				audit,
				"Audit: ",
				audit.auditCarryOut,
				colors.lightgrey
			)
		);

	const getAllDueAudits = () =>
		audits.map((audit) =>
			createAuditEvent(
				audit,
				"Audit: ",
				getNextDate(audit.auditCarryOut, audit.auditCycle),
				colors.lightgrey
			)
		);

	const getAllOpenToDos = () => {
		return audits.flatMap((audit) =>
			audit.auditClusters.flatMap((cluster) =>
				cluster.clusterQuestions.flatMap((question) =>
					question.questionToDos
						.filter((todo) => todo.todoIsDone === "false")
						.map((todo) => ({
							id: audit._id + "_" + cluster._id + "_" + todo._id,
							title: "Todo: " + todo.todoTitle,
							date: todo.todoDueAt,
							backgroundColor: colors.lightgrey,
						}))
				)
			)
		);
	};

	const getAllDates = () => [
		...getAllCarriedOutAudits(),
		...getAllDueAudits(),
		...getAllOpenToDos(),
	];

	const dateCellRender = (value) => {
		const currentDay = value.format("YYYY-MM-DD");
		const listData = getAllDates().filter((item) => item.date === currentDay);

		const compactStyle = {
			backgroundColor: "#EEE",
			cursor: "pointer",
			padding: "0",
			margin: "0",
			fontSize: "0.9em",
			display: "block", // Makes the span behave like a block element
			width: "100%", // Ensures it takes up full width of parent
			textAlign: "left",
		};

		return (
			<ul
				className="events"
				style={{ listStyleType: "none", padding: "0", margin: "0" }}
			>
				{listData.map((item) => (
					<li key={item.id} style={{ marginBottom: "2px" }}>
						<span
							style={compactStyle}
							onClick={() => navigateEvent(item)}
						>
							{item.title}
						</span>
					</li>
				))}
			</ul>
		);
	};

	const navigateEvent = (item) => {
		if (item.title.startsWith("A")) {
			navigate(`/audits/${item.id}/clusters`);
		} else {
			const [auditId, clusterId, todoId] = item.id.split("_");
			navigate(`/audits/${auditId}/clusters/${clusterId}/questions`);
		}
	};

	const cellRender = (current, info) => {
		if (info.type === "date") return dateCellRender(current);
		return info.originNode;
	};

	const breadcrumbItems = [
		{ title: "Dashboard", href: "/" },
		{ title: "Calendar" },
	];

	return (
		<>
			<Box className="backgroundBox">
				{/* BREADCRUMBS */}
				<Breadcrumb items={breadcrumbItems} />
				<Calendar
					cellRender={cellRender}
					firstDayOfWeek={1}
					style={{ borderRadius: 10, padding: 10, marginTop: 10 }}
				/>
			</Box>
		</>
	);
}
