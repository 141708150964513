import axiosInstance from "./axiosInstance"

/*
  @desc: Update a current question for an audit
  @para: data -> { auditId, clusterId, questionId, questionData }
*/
async function updateAuditQuestion(data) {
	console.log(data)
	const response = await axiosInstance.put(
		`/audits/${data.auditId}/clusters/${data.clusterId}/questions/${data.questionId}`,
		data.questionData
	);
	console.log(response.data)
	return response.data;
}

export { updateAuditQuestion }