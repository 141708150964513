export const toggleAllQuestionsInCluster = (cluster, setSelectedClusters, setSelectedQuestionsInCluster) => {
    setSelectedClusters((prevClusters) => {
        const index = prevClusters.findIndex(c => c._id === cluster._id);
        if (index !== -1) {
            // Remove the cluster from selectedClusters
            prevClusters.splice(index, 1);
            setSelectedQuestionsInCluster((prevState) => {
                const newState = { ...prevState };
                delete newState[cluster._id];
                return newState;
            });
        } else {
            // Add the cluster to selectedClusters
            prevClusters.push({
                ...cluster,
                clusterQuestions: [...cluster.clusterQuestions],
            });
            setSelectedQuestionsInCluster((prevState) => ({
                ...prevState,
                [cluster._id]: cluster.clusterQuestions.map((q) => q._id),
            }));
        }
        return [...prevClusters];
    });
};

export const toggleQuestionInCluster = (cluster, question, setSelectedClusters) => {
    setSelectedClusters((prevClusters) => {
        const clusterIndex = prevClusters.findIndex(c => c._id === cluster._id);
        if (clusterIndex !== -1) {
            const questionIndex = prevClusters[clusterIndex].clusterQuestions.findIndex(q => q._id === question._id);
            if (questionIndex !== -1) {
                // Remove the question
                prevClusters[clusterIndex].clusterQuestions.splice(questionIndex, 1);
                
                // If no questions are selected for the cluster, remove the cluster.
                if (prevClusters[clusterIndex].clusterQuestions.length === 0) {
                    prevClusters.splice(clusterIndex, 1);
                }
            } else {
                // Add the question
                prevClusters[clusterIndex].clusterQuestions.push(question);
            }
        } else {
            // If the cluster isn't selected yet, initialize it
            prevClusters.push({
                ...cluster,
                clusterQuestions: [question],
            });
        }

        return [...prevClusters];
    });
};
