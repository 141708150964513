import { Card, Col, Row, Empty } from "antd";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

// COMPONENTS
import LoadingScreen from "../components/LoadingScreen";
import ErrorScreen from "../components/ErrorScreen";

// CONTEXT
import { useTemplates } from "../context/Template/TemplateContext";

export default function TemplateCards({ type, setSelectedClusters, next }) {
	const { templates, templatesLoading, templatesError } = useTemplates();
	const navigate = useNavigate();

	//useEffect(() => {}, [templates]);

	// DISPLAY LOADING PAGE
	if (templatesLoading) return <LoadingScreen />;

	// DISPLAY ERROR PAGE
	if (templatesError) return <ErrorScreen />;

	function handleTemplateClick(template) {
		if(type === "clusters") {
			navigate(`/templates/${template._id}/settings`)
		}
		if(type === "createAudit") {
			const updatedTemplateClusters = template.auditClusters.map((cluster) => {
				return {
					_id: cluster._id,
					clusterName: cluster.clusterName,
					clusterQuestions: cluster.clusterQuestions.map((question) => {
						return {
							questionName: question.questionName,
							questionWeight: question.questionWeight,
							questionType: question.questionType,
						}
					})
				}
			})
			setSelectedClusters(updatedTemplateClusters)
			next()
		}
	}

	return (
		<Row gutter={[16, 16]} wrap={true}>
			{templates.length == 0 ? (
				<Box
					display="flex"
					flexDirection="row"
					flex={1}
					justifyContent="center"
					marginTop="20px"
				>
					<Empty description="Keine Vorlagen" />
				</Box>
			) : (
				templates.map((template, index) => (
					<Col span={8} key={index}>
						<Card
							hoverable
							bordered={false}
							title={template.templateName}
							onClick={() => handleTemplateClick(template)}
						>
							{template.templateDescription}
						</Card>
					</Col>
				))
			)}
		</Row>
	);
}
