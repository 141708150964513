// CALCULATE PROGRESS % MUST HAVES FOR WHOLE AUDIT
export function calcProgressMustHavesAudit(audit) {
    let mhTotal = 0;
    let mh = 0;

    for (const cluster of audit.auditClusters) {
        const [mustHaves, mustHavesTotal] = calcProgressMustHaves(cluster);
        mhTotal = mhTotal + mustHavesTotal;
        mh = mh + mustHaves;
    }
    return ((mh / mhTotal) * 100).toFixed(0);
}

// CALCULATE PROGRESS % POINTS FOR WHOLE AUDIT
export function calcProgressPointsAudit(audit) {
    let pTotal = 0;
    let p = 0;

    for (const cluster of audit.auditClusters) {
        const [points, pointsTotal] = calcProgressPoints(cluster);
        pTotal = pTotal + pointsTotal;
        p = p + points;
    }
    return ((p / pTotal) * 100).toFixed(0);
}


// CALCULATE PROGRESS POINTS FOR A CLUSTER
export function calcProgressPoints(cluster) {
    let pointsTotal = 0;
    let points = 0;

    for (const question of cluster.clusterQuestions) {
        if (
            question.questionWeight !== "P" &&
            question.questionWeight !== "N"
        ) {
            pointsTotal = pointsTotal + parseInt(question.questionWeight);
            if (question.questionIsDone === "true") {
                points = points + parseInt(question.questionWeight);
            }
        }
    }
    const pointsPercent = pointsTotal ? (points / pointsTotal) * 100 : 0;
    return [points, pointsTotal, pointsPercent];
}

// CALCULATE MUST HAVES FOR A CLUSTER
export function calcProgressMustHaves(cluster) {
    let mustHavesTotal = 0;
    let mustHaves = 0;

    for (const question of cluster.clusterQuestions) {
        if (question.questionWeight === "P") {
            mustHavesTotal = mustHavesTotal + 1;
            if (question.questionIsDone === "true") {
                mustHaves = mustHaves + 1;
            }
        }
    }
    const mustHavesPercent = mustHavesTotal ? (mustHaves / mustHavesTotal) * 100 : 0;
    return [mustHaves, mustHavesTotal, mustHavesPercent];
}