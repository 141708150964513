import axiosInstance from "./axiosInstance"

/*
  @desc: Get all clusters from the database
*/
async function getClusters() {
  const response = await axiosInstance.get(`/clusters`);
  return response.data;
}

/*
  @desc: Create a new Cluster
  @para: data -> { clusterData }
*/
async function createCluster(data) {
  const response = await axiosInstance.post(`/clusters`, data);
  return response.data;
}

/*
  @desc: Update a current cluster
  @para: c -> { clusterId, clusterData }
*/
async function updateCluster(c) {
  const response = await axiosInstance.put(`/clusters/${c.clusterId}`, c.clusterData);
  return response.data;
}

/*
  @desc: Delete a cluster
  @para: c -> { clusterId }
*/
async function deleteCluster(id) {
  const response = await axiosInstance.delete(`/clusters/${id}`);
  return response.data;
}

export { getClusters, createCluster, updateCluster, deleteCluster };