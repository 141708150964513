import { useMutation, useQueryClient } from "react-query";
import { createCluster, updateCluster, deleteCluster } from "../../api/clusterService"; // Assuming the path to the services
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export function useCreateClusterMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(createCluster, {
        onSuccess: () => {
            toast.success("Cluster erfolgreich erstellt");
            queryClient.invalidateQueries("clusters");
            navigate("/clusters"); // Assuming you want to redirect to "/clusters" after creating
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}

export function useUpdateClusterMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(updateCluster, {
        onSuccess: () => {
            toast.success("Cluster erfolgreich aktualisiert");
            queryClient.invalidateQueries("clusters");
            navigate("/clusters"); // Assuming you want to redirect to "/clusters" after updating
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}

export function useDeleteClusterMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(deleteCluster, {
        onSuccess: () => {
            toast.success("Cluster erfolgreich gelöscht");
            queryClient.invalidateQueries("clusters");
            navigate("/clusters"); // Assuming you want to redirect to "/clusters" after deleting
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}
