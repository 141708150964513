import { Form, Input } from "antd";

export default function TemplateForm({ form, templateFormRef, onFinish }) {

	// JSX COMPONENTS
	const { TextArea } = Input;
	return (
		<div style={{ textAlign: "start" }}>
			<Form
				form={form}
				ref={templateFormRef}
				onFinish={onFinish}
				name="template_formular"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ width: 500 }}
			>
				<Form.Item
					name="templateName"
					label="Vorlage Name"
					rules={[
						{
							required: true,
							message: "Bitte geben Sie den Vorlage-Namen ein!",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="templateDescription"
					label="Vorlagen Beschreibung"
					rules={[
						{
							required: true,
							message: "Bitte geben Sie die Vorlagen Beschreibung ein!",
						},
					]}
				>
					<TextArea placeholder="Bitte geben Sie die Beschreibung ein" rows={4} />
				</Form.Item>
			</Form>
		</div>
	);
}
