import { Radio } from "antd";

export function TypeFilter({setQuestionType}) {

	return (
		<Radio.Group
			size="large"
			defaultValue="a"
			onChange={(e) => setQuestionType(e.target.value)}
		>
			<Radio.Button value="a">Alle</Radio.Button>
			<Radio.Button value="S">Spezifisch</Radio.Button>
			<Radio.Button value="G">Allgemein</Radio.Button>
		</Radio.Group>
	);
}
