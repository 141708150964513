import { useQuery } from "react-query";
import { createContext, useContext } from "react";
import { getClusters } from "../../api/clusterService";

export const ClustersContext = createContext({});

export function ClustersContextProvider({ children }) {
	const { data: clusters, isLoading, error } = useQuery("clusters", getClusters);

	return (
		<ClustersContext.Provider value={{ clusters, clustersLoading: isLoading, clustersError: error }}>
			{children}
		</ClustersContext.Provider>
	);
}

export function useClusters() {
    return useContext(ClustersContext);
}