import { useState } from "react";
import { Box } from "@mui/material";
import { Modal, Table, Radio, Space, Breadcrumb, Input, Tag } from "antd";
import {
	ExclamationCircleOutlined,
	EditOutlined,
	DeleteOutlined,
	UserOutlined,
} from "@ant-design/icons";

// COMPONENTS
import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";
import TopBarButton from "../_Global/TopBarButton";
import { ClientFilter } from "../../components/Filter"
import CellButtonNavigate from "../../components/CellButtonNavigate"

// LOCAL COMPONENTS
import ClientDrawer from "./components/ClientDrawer";

// CONTEXT & HOOKs
import { useClients, useDeleteClientMutation } from "../../context/Client";
import { useAudits } from "../../context/Audit";

// UTILS
import { countAuditsForClient, countToDosForClient, unifiedFilter } from "../../utils/Audit";

// MAIN
export default function Clients() {
	// GET CONTEXT
	const { clients, clientLoading, clientError } = useClients();
	const { audits, auditsLoading, auditsError } = useAudits();
	const deleteClientMutation = useDeleteClientMutation();

	const { Search } = Input;
	const [searchValue, setSearchValue] = useState("");
	const handleSearch = (e) => {
		setSearchValue(e.target.value);
	};

	// HANDLE THE FORM DATA
	const [clientForm, setClientForm] = useState({});

	// CHECK WETHER USER IS EDITING
	const [isEditing, setIsEditing] = useState(false);

	// HANDLE DRAWER OPEN
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	// DEFINITON OF COLUMNS
	const columns = [
		{
			title: "Kundenname",
			dataIndex: "clientName",
			key: "clientName",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "90%",
						height: "80%",
					}}
				>
					<CellButtonNavigate
						to={`/clients/${record.id}/history`}
						text={record.clientName}
					/>
				</div>
			),
			sorter: (a, b) => a.clientName.localeCompare(b.clientName),
		},
		{
			title: "Ansprechpartner",
			dataIndex: "clientKeyPerson",
			key: "clientKeyPerson",
			render: (text) => (
				<Space>
					<UserOutlined />
					{text}
				</Space>
			),
		},
		{
			title: "Abgeschlossen",
			dataIndex: "countAuditsDone",
			key: "countAuditsDone",
			render: (text) => (
				<Space>
					{
						text !== 0 && 
						<Tag color="green">{text}</Tag>
					}
				</Space>
			),
			sorter: (a, b) => a.countAuditsDone - b.countAuditsDone,
		},
		{
			title: "Offen",
			dataIndex: "countAuditsOpen",
			key: "countAuditsOpen",
			render: (text) => (
				<Space>
					{
						text !== 0 && 
						<Tag color="red">{text}</Tag>
					}
				</Space>
			),
			sorter: (a, b) => a.countAuditsOpen - b.countAuditsOpen,
		},
		{
			title: "ToDos",
			dataIndex: "countToDosOpen",
			key: "countToDosOpen",
			render: (text) => (
				<Space>
					{
						text !== 0 &&
						<Tag>{text}</Tag>
					}
				</Space>
			),
			sorter: (a, b) => a.countToDosOpen - b.countToDosOpen,
		},
		{
			title: "Adresse",
			dataIndex: "clientAddress",
			key: "clientAddress",
		},
		{
			title: "Aktionen",
			key: "actions",
			render: (_, record) => (
				<Space size="middle">
					<EditOutlined
						onClick={() => {
							setIsEditing(true);
							setClientForm(record);
							handleOpen();
						}}
					/>
					<DeleteOutlined
						onClick={() => {
							Modal.confirm({
								title: "Kunde sicher löschen?",
								icon: <ExclamationCircleOutlined />,
								onOk: () => {
									deleteClientMutation.mutate(record.id);
								},
							});
						}}
					/>
				</Space>
			),
		},
	];

	// DISPLAY LOADING PAGE
	if (clientLoading || auditsLoading) return <LoadingScreen />;

	// DISPLAY ERROR PAGE
	if (clientError || auditsError) return <ErrorScreen />;

	// TRANSFORM THE DATA FROM THE DATABASE FOR THE DATAGRID
	const transformedClients = clients.map((client) => ({
		id: client._id,
		clientName: client.clientName,
		clientKeyPerson: client.clientKeyPerson,
		clientAddress: client.clientAddress,
		countAuditsDone: countAuditsForClient(audits, client.clientName, "true"),
		countAuditsOpen: countAuditsForClient(audits, client.clientName, "false"),
		countToDosOpen: countToDosForClient(
			audits,
			client.clientName,
			"false"
		),
	}));

	// FILTER FOR SEARCH BOX
	const filteredClients = unifiedFilter("clients", transformedClients, searchValue)

	const breadcrumbItems = [
		{ title: "Dashboard", href: "/" },
		{ title: "Clients" },
	];

	return (
		<>
			<Box className="backgroundBox">
				{/* Breadcrumbs */}
				<Breadcrumb items={breadcrumbItems} />

				<TopBarButton
					headline="Alle Kunden"
					subheadline="Hier werden alle Kunden angezeigt."
					btnText="Kunde anlegen"
					onClick={() => {
						setIsEditing(false);
						handleOpen();
					}}
				/>

				{/* TABLE */}
				<Box display="flex" flexDirection="column" flexGrow={1}>
					<Table
						title={() => tableHeader()}
						bor
						size="small"
						columns={columns}
						dataSource={filteredClients}
						onChange={(e) => handleSearch(e.target.value)}
					/>
				</Box>

				<ClientDrawer
					isEditing={isEditing}
					setIsEditing={setIsEditing}
					initClientForm={clientForm}
					open={open}
					onClose={handleClose}
				/>
			</Box>
		</>
	);

	function tableHeader() {
		return (
			<Box
				height="60px"
				px="10px"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<ClientFilter clients={clients} />

				<Search
					onChange={handleSearch}
					size="large"
					placeholder="Kunde suchen ..."
					style={{
						width: 250,
					}}
				/>
			</Box>
		);
	}
}
