// HELPERS -----
function includesIgnoreCase(source, searchTerm) {
	return source.toLowerCase().includes(searchTerm.toLowerCase());
}

// FILTERS -----

// FILTER CLUSTERS BY SEARCH
function filterClusters(clusters, searchTerm) {
	return clusters.filter((cluster) =>
		includesIgnoreCase(cluster.clusterName, searchTerm)
	);
}

// FILTER CLUSTERS BY SEARCH
function filterClients(clients, searchTerm) {
	return clients.filter(
		(client) =>
			includesIgnoreCase(client.clientName, searchTerm) ||
			includesIgnoreCase(client.clientKeyPerson, searchTerm)
	);
}

// FILTER QUESTIONS BY SEARCH AND TYPE
function filterClusterQuestionsByType(clusterQuestions, searchTerm, type) {
	return clusterQuestions.filter(
		(question) =>
			includesIgnoreCase(question.questionName, searchTerm) &&
			(type === "a" || question.questionType === type)
	);
}

// FILTER QUESTIONS BY SEARCH AND WEIGHT
function filterClusterQuestionsByWeight(clusterQuestions, searchTerm, weight) {
	return clusterQuestions.filter(
		(question) =>
			includesIgnoreCase(question.questionName, searchTerm) &&
			(weight === "a" || question.questionWeight === weight)
	);
}

// FILTER AUDITS BY SEARCH AND STATUS
function filterAudits(audits, searchTerm, auditIsDone) {
	return audits.filter(
		(audit) =>
			(includesIgnoreCase(audit.auditName, searchTerm) ||
				includesIgnoreCase(audit.auditClient, searchTerm) ||
				includesIgnoreCase(audit.auditAuditor, searchTerm)) &&
			(auditIsDone === "a" || audit.auditIsDone === auditIsDone)
	);
}

// FILTER QUESITONS MAINLY BY SEARCH AND STATUS
function filterQuestions(clusterQuestions, searchTerm, questionIsDone) {
	const matchesQuestionFields = (question) =>
		includesIgnoreCase(question.questionName, searchTerm) ||
		includesIgnoreCase(question.questionClientNote, searchTerm) ||
		includesIgnoreCase(question.questionAuditorNote, searchTerm);

	const matchesTodo = (question) =>
		question.questionToDos.some(
			(todo) =>
				includesIgnoreCase(todo.todoTitle, searchTerm) ||
				includesIgnoreCase(todo.todoKeyPerson, searchTerm)
		);

	return clusterQuestions.filter(
		(question) =>
			(matchesQuestionFields(question) || matchesTodo(question)) &&
			(questionIsDone === "a" || question.questionIsDone === questionIsDone)
	);
}

// UNIFIED FILTER
export function unifiedFilter(entity, data, searchTerm, radioValue) {
	if (entity === "clients") return filterClients(data, searchTerm);
	if (entity === "clusters") return filterClusters(data, searchTerm);
	if (entity === "clusterQuestionsByType")
		return filterClusterQuestionsByType(data, searchTerm, radioValue);
	if (entity === "clusterQuestionsByWeight")
		return filterClusterQuestionsByWeight(data, searchTerm, radioValue);
	if (entity === "audits") return filterAudits(data, searchTerm, radioValue);
	if (entity === "questions")
		return filterQuestions(data, searchTerm, radioValue);
	return [];
}
