import * as yup from "yup";

const loginSchema = yup.object().shape({
    userEmail: yup
        .string()
        .email("Ungültige Email Adresse")
        .required("Email ist ein Pflichtfeld"),
    userPassword: yup
        .string()
        .required("Passwort ist ein Pflichtfeld")
        .min(6, "Passwort muss mindestens 6 Zeichen lang sein"),
});

export default loginSchema;