import React, { useEffect } from 'react';

export default function EmailVerification() {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/login';
    }, 2000);
  }, []);

  return (
    <div>Email erfolgreich verifiziert.</div>
  );
}
