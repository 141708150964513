import { useState } from "react";
import { tokens } from "../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Table, Tag, Input, Breadcrumb, Progress, Avatar, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import {
	ExclamationCircleOutlined,
} from "@ant-design/icons";

//COMPONENTS
import CellButtonNavigate from "../../components/CellButtonNavigate";
import TopBarDropdown from "../_Global/TopBarDropdown";
import DownloadReport from "../../components/DownloadReport";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";
import { ClusterFilter } from "../../components/Filter";

// CONTEXT & HOOKS
import {
	useAudits,
	useDeleteAuditMutation,
	useUpdateAuditMutation,
} from "../../context/Audit";

// UTILS
import {
	countToDosForCluster,
	calcProgressPoints,
	calcProgressMustHaves,
	unifiedFilter,
} from "../../utils/Audit";
import { getRightDateFormat, getNextDate } from "../../utils/Date";

// MAIN
export default function AuditClusters() {
	const { audits, auditsLoading, auditsError } = useAudits();
	const updateAuditMutation = useUpdateAuditMutation();
	const deleteAuditMutation = useDeleteAuditMutation();
	const { auditId } = useParams();
	const navigate = useNavigate();

	// GET THEME AND COLORS
	const theme = useTheme();
	const colors = tokens(theme.palette);

	// SEARCH
	const { Search } = Input;
	const [searchTerm, setSearchTerm] = useState("");
	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	// DEFINITION OF COLUMNS
	const columns = [
		{
			title: "Cluster",
			dataIndex: "clusterName",
			key: "clusterName",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "90%",
						height: "80%",
					}}
				>
					<CellButtonNavigate
						to={`/audits/${auditId}/clusters/${record.id}/questions`}
						text={record.clusterName}
					/>
				</div>
			),
			sorter: (a, b) => a.clusterName.localeCompare(b.clusterName),
		},
		{
			title: "Must Haves",
			dataIndex: "progressMustHaves",
			key: "progressMustHaves",
			width: "30%",
			render: (text, record) => {
				if (record.mustHavesTotal != 0) {
					return <Progress percent={Math.round(text)} />;
				}
				return <Typography color="#AAA">Keine Must Haves</Typography>;  // Render nothing if mustHavesTotal is "0"
			},
			sorter: (a, b) => a.progressMustHaves - b.progressMustHaves,
		},		
		{
			title: "Punkte",
			dataIndex: "progressPoints",
			key: "progressPoints",
			width: "30%",
			render: (_, record) => (
				<Progress percent={Math.round(record.progressPoints)} />
			),
			sorter: (a, b) => a.progressPoints - b.progressPoints,
		},
		{
			title: "ToDos",
			dataIndex: "countOpenToDos",
			key: "countOpenToDos",
			render: (_, record) =>
				record.countOpenToDos !== 0 && <Tag>{record.countOpenToDos}</Tag>,
			sorter: (a, b) => a.countOpenToDos - b.countOpenToDos,
		},
	];

	// DISPLAY LOADING PAGE
	if (auditsLoading) return <LoadingScreen />;

	// DISPLAY ERROR PAGE
	if (auditsError) return <ErrorScreen />;

	// FILTER FOR CURRENT AUDIT
	const audit = audits.find((audit) => audit._id === auditId);

	// CHANGE AUDIT STATUS
	function changeAuditStatus() {
		updateAuditMutation.mutate({
			auditId: audit._id,
			auditData: {
				...audit,
				auditIsDone: audit.auditIsDone == "false" ? "true" : "false",
			},
		});
	}

	// DELETE AUDIT
	function handleDeleteAudit() {
		deleteAuditMutation.mutate({
			auditId: audit._id,
		});
	}

	// OPTIONS FOR DROPDOWN
	const items = [
		{
			key: "1",
			label: (
				<a onClick={() => navigate(`/audits/${auditId}/settings`)}>
					Audit Einstellungen
				</a>
			),
		},
		{
			key: "2",
			label: <a onClick={() => {}}>Audit Logs</a>,
		},
		{
			type: "divider",
		},
		{
			key: "3",
			label: (
				<a onClick={() => changeAuditStatus()}>
					{audit.auditIsDone == "false"
						? "Audit abschließen"
						: "Audit wieder aufnehmen "}
				</a>
			),
		},
		{
			key: "4",
			label: <a onClick={() => {}}>Testat herunterladen</a>,
			disabled: true,
		},
		{
			type: "divider",
		},
		{
			key: "5",
			label: (
				<a
					onClick={() => {
						Modal.confirm({
							title: `Audit "${audit.auditName}" wirklich löschen?`,
							icon: <ExclamationCircleOutlined />,
							onOk: () => {
								deleteAuditMutation.mutate(audit._id);
							},
						});
					}}
				>
					Audit löschen
				</a>
			),
			danger: true,
		},
	];

	// TRANSFORM DATA FOR THE TABLE
	const transformedClusters = audit.auditClusters.map((cluster) => {
		const [mustHaves, mustHavesTotal, mustHavesPercent] =
			calcProgressMustHaves(cluster);
		const [points, pointsTotal, pointsPercent] = calcProgressPoints(cluster);
		return {
			id: cluster._id,
			clusterName: cluster.clusterName,
			mustHaves,
			mustHavesTotal,
			progressMustHaves: mustHavesPercent,
			points,
			pointsTotal,
			progressPoints: pointsPercent,
			countOpenToDos: countToDosForCluster(cluster),
		};
	});

	// FILTER CLUSTERS FOR SEARCH BAR
	const filteredClusters = unifiedFilter(
		"clusters",
		transformedClusters,
		searchTerm
	);

	const breadcrumbItems = [
		{ title: "Dashboard", href: "/" },
		{ title: "Audits", href: "/audits" },
		{ title: audit.auditName },
	];

	return (
		<>
			<Box className="backgroundBox">
				{/* BREADCRUMBS */}
				<Breadcrumb items={breadcrumbItems} />

				<TopBarDropdown
					headline={audit.auditName}
					subheadline="Übersicht über das aktuelle Audit"
					items={items}
				/>

				<Box display="flex" flexDirection="column" flexGrow={1}>
					{/* TABLE */}
					<Table
						title={() => tableHeader()}
						size="small"
						columns={columns}
						dataSource={filteredClusters}
						onChange={(e) => handleSearch(e.target.value)}
					/>
				</Box>
			</Box>
		</>
	);

	function tableHeader() {
		return (
			<Box display="flex" flexDirection="column" flex="1">
				{/* UPPER PART */}
				<Box
					height="60px"
					px="10px"
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box display="flex">
						<Avatar
							style={{
								backgroundColor: colors.lightgrey,
								verticalAlign: "middle",
								marginRight: "10px",
							}}
							size="large"
						>
							{audit.auditClient.clientName[0]}
						</Avatar>
						<Box display="flex" flexDirection="column">
							<Typography variant="h6" color={colors.midgrey}>
								Kunde
							</Typography>
							<Typography variant="h4" fontWeight="bold">
								{audit.auditClient.clientName}
							</Typography>
						</Box>
					</Box>

					<Box display="flex">
						<Box display="flex" flexDirection="column" marginRight={5}>
							<Typography variant="h6" color={colors.midgrey}>
								Durchgeführt am:
							</Typography>
							<Typography variant="h4" fontWeight="bold">
								{getRightDateFormat(audit.auditCarryOut)}
							</Typography>
						</Box>
						<Box display="flex" flexDirection="column" marginRight={5}>
							<Typography variant="h6" color={colors.midgrey}>
								Nächstes am:
							</Typography>
							<Typography variant="h4" fontWeight="bold">
								{getNextDate(audit.auditCarryOut, audit.auditCycle)}
							</Typography>
						</Box>
						<DownloadReport auditId={audit._id} />
					</Box>
				</Box>

				{/* LOWER PART */}
				<Box
					height="60px"
					px="10px"
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<ClusterFilter audit={audit} />

					<Search
						onChange={handleSearch}
						size="large"
						placeholder="Cluster suchen ..."
						style={{
							width: 250,
						}}
					/>
				</Box>
			</Box>
		);
	}
}
