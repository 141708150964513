import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

// API
import {
	registerUser,
	generate2FASecret,
	validate2FACode,
	logoutUser,
	changePassword,
} from "../../api/authService";

// CONTEXT
import { useUser } from "../../context/User";

// REGISTER
export function useCreateUserMutation() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	return useMutation(registerUser, {
		onSuccess: () => {
			toast.success(
				"Registrierung Erfolgreich. \n Bitte E-Mail bestätigen."
			);
			queryClient.invalidateQueries("users");
			navigate("/login");
		},
		onError: (error) => {
			const errorMessage =
				error.response?.data?.message ||
				"Ein unbekannter Fehler ist aufgetreten";
			toast.error(errorMessage);
		},
	});
}

// 2FA
export function useInit2FAMutation(setOtpURL, handleQRModalOpen) {
	return useMutation(generate2FASecret, {
		onSuccess: (data) => {
			setOtpURL(data.qrCodeDataURL);
			handleQRModalOpen();
		},
		onError: (error) => {
			toast.error("Fehler beim Initiieren von 2FA: " + error.message);
		},
	});
}

// VALIDATE 2FA
export function useValidate2FAMutation(handleClose) {
	const navigate = useNavigate();
	const { setUser } = useUser();

	return useMutation(validate2FACode, {
		onSuccess: (data) => {
			setUser(data);
			toast.success("2FA erfolgreich validiert");
			handleClose();
			setTimeout(() => navigate("/"), 1000);
		},
		onError: (error) => {
			toast.error("Fehler beim Initiieren von 2FA: " + error.message);
		},
	});
}

// LOGIN FUNCTION IS LOCAL IN THE LOGIN SCENE
// --

// LOGOUT USER
export function useLogoutUserMutation() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { setUser } = useUser();

	return useMutation(logoutUser, {
		onSuccess: (data) => {
			toast.success("Erfolgreich ausgeloggt");
			setUser(null);
			queryClient.invalidateQueries("users");
			setTimeout(() => navigate("/"), 1000);
		},
		onError: (_) => {
			toast.error("Ein unbekannter Fehler ist aufgetreten");
		},
	});
}

// CHANGE PASSWORD
export function useUpdateUserPasswordMutation() {
	const queryClient = useQueryClient();

	return useMutation(changePassword, {
		onSuccess: () => {
			toast.success("Passwort erfolgreich geändert");
			queryClient.invalidateQueries("users");
		},
		onError: (error) => {
			const errorMessage =
				error.response?.data?.message ||
				"Ein unbekannter Fehler ist aufgetreten";
			toast.error(errorMessage);
		},
	});
}
