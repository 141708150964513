import { Box, useTheme, Skeleton, Fade } from "@mui/material";
import { tokens } from "../theme";

export default function LoadingScreen() {
	// GET THEME AND COLORS
	const theme = useTheme();
	const colors = tokens(theme.palette);

	return (
		<Box className="backgroundBox">
			<Fade in={Boolean(true)} timeout={3000}>
				<Box>
				<Skeleton
					width="40%"
					height="100px"
					sx={{
						my: "0px",
						bgcolor: colors.midgrey,
					}}
				/>

				<CustomSkeleton />
				<CustomSkeleton />
				<CustomSkeleton />
				</Box>
			</Fade>
		</Box>
	);
}

function CustomSkeleton() {
	// GET THEME AND COLORS
	const theme = useTheme();
	const colors = tokens(theme.palette);

	return (
		<Skeleton
			width="80%"
			height="50px"
			sx={{
				my: "0px",
				bgcolor: colors.midgrey,
			}}
		/>
	);
}
