import React, { useState } from "react";
import { Box } from "@mui/material";
import {
	Table,
	Tag,
	Radio,
	Input,
	Breadcrumb,
	Modal,
	Space,
	Button,
} from "antd";
import {
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";

// COMPONENTS
import CellButtonNavigate from "../../components/CellButtonNavigate";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";
import TopBarButton from "../_Global/TopBarButton";

// LOCAL COMPONENTS
import ClusterDrawer from "./components/ClusterDrawer";

// CONTEXT & HOOKS
import { useClusters, useDeleteClusterMutation } from "../../context/Cluster";

// MAIN
export default function Clusters() {
	const { clusters, clustersLoading, clustersError } = useClusters();
	const deleteClusterMutation = useDeleteClusterMutation();

	// SEARCH
	const { Search } = Input;
	const [searchValue, setSearchValue] = useState("");
	const handleSearch = (e) => {
		setSearchValue(e.target.value);
	};

	// HANDLE THE FORM DATA
	const [clusterForm, setClusterForm] = useState({});
	const [isEditing, setIsEditing] = useState(false);

	// HANDLE MODAL OPEN
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	// DEFINITON OF COLUMNS
	const columns = [
		{
			title: "Clustername",
			dataIndex: "clusterName",
			key: "clusterName",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "90%",
						height: "80%",
					}}
				>
					<CellButtonNavigate
						to={`/clusters/${record.id}/questions`}
						text={record.clusterName}
					/>
				</div>
			),
			sorter: (a, b) => a.clusterName.localeCompare(b.clusterName),
		},
		{
			title: "Beschreibung",
			dataIndex: "clusterDescription",
			key: "clusterDescription",
			width: "60%",
		},
		{
			title: "Referenz",
			dataIndex: "clusterReference",
			key: "clusterReference",
		},
		{
			title: "Anz. Controls",
			dataIndex: "countQuestions",
			key: "countQuestions",
			render: (text) => <Tag>{text}</Tag>,
		},
		{
			title: "Aktionen",
			key: "actions",
			render: (_, record) => (
				<Space size="middle">
					<EditOutlined
						onClick={() => {
							setClusterForm(record);
							setIsEditing(true);
							handleOpen();
						}}
					/>
					<DeleteOutlined
						onClick={() => {
							Modal.confirm({
								title: `Cluster "${record.clusterName}" wirklich löschen?.`,
								icon: <ExclamationCircleOutlined />,
								onOk: () => {
									deleteClusterMutation.mutate(record.id);
								},
							});
						}}
					/>
				</Space>
			),
		},
	];

	// DISPLAY LOADING PAGE
	if (clustersLoading) return <LoadingScreen />;

	// DISPLAY ERROR PAGE
	if (clustersError) return <ErrorScreen />;

	// TRANSFORM THE DATA FROM THE DATABASE FOR THE DATAGRID
	const transformedClusters = clusters.map((cluster) => ({
		id: cluster._id,
		clusterName: cluster.clusterName,
		clusterDescription: cluster.clusterDescription,
		clusterReference: cluster.clusterReference,
		countQuestions: cluster.clusterQuestions.length,
	}));

	// PROVIDE FILTERED CLUSTERS FOR SEARCH BAR
	const filteredClusters = transformedClusters.filter(
		(cluster) =>
			cluster.clusterName
				.toLowerCase()
				.includes(searchValue.toLowerCase()) ||
			cluster.clusterDescription
				.toLowerCase()
				.includes(searchValue.toLowerCase()) ||
			cluster.clusterReference
				.toLowerCase()
				.includes(searchValue.toLowerCase())
	);

	const breadcrumbItems = [
		{ title: "Dashboard", href: "/" },
		{ title: "Clusters" },
	];

	// RENDER
	return (
		<>
			<Box className="backgroundBox">
				{/* BREADCRUMBS */}
				<Breadcrumb items={breadcrumbItems} />

				<TopBarButton
					headline="Alle Cluster"
					subheadline="Hier werden alle Cluster angezeigt."
					btnText="Cluster anlegen"
					onClick={() => {
						setIsEditing(false);
						handleOpen();
					}}
				/>

				<Box display="flex" flexDirection="column" flexGrow={1}>
					{/* TABLE */}
					<Table
						title={() => tableHeader()}
						size="small"
						columns={columns}
						dataSource={filteredClusters}
						onChange={(e) => handleSearch(e.target.value)}
						pagination={{ pageSize: 6 }}
					/>
				</Box>

				<ClusterDrawer
					isEditing={isEditing}
					setIsEditing={setIsEditing}
					initClusterForm={clusterForm}
					open={open}
					onClose={handleClose}
				/>
			</Box>
		</>
	);

	function tableHeader() {
		return (
			<Box
				height="60px"
				px="10px"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Radio.Group defaultValue="a" size="large">
					<Radio.Button value="a">
						Clusters({clusters.length})
					</Radio.Button>
				</Radio.Group>

				<Search
					onChange={handleSearch}
					size="large"
					placeholder="Cluster suchen ..."
					style={{
						width: 300,
					}}
				/>
			</Box>
		);
	}
}
