import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Table, Tag, Input, Breadcrumb, Space, Modal, Button } from "antd";
import {
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleOutlined,
	UploadOutlined,
} from "@ant-design/icons";

// COMPONENTS
import TopBarButton from "../_Global/TopBarButton";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";
import { WeightFilter, TypeFilter } from "../../components/Filter";

// LOCAL COMPONENTS
import QuestionDrawer from "./components/QuestionDrawer";

// CONTEXT
import { useClusters } from "../../context/Cluster";
import { useDeleteQuestionMutation } from "../../context/Question";

// API
import { unifiedFilter } from "../../utils/Audit";

// MAIN
export default function Questions() {
	// GET CLUSTERID FROM URL
	const { clusters, clustersLoading, clustersError } = useClusters();
	const deleteQuestionMutation = useDeleteQuestionMutation();
	let { clusterId } = useParams();

	// SEARCH
	const { Search } = Input;
	const [searchTerm, setSearchTerm] = useState("");
	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	// FILTER
	const [questionWeight, setQuestionWeight] = useState("a"); // default all
	const [questionType, setQuestionType] = useState("a"); // default all

	// HANDLE DRAWER: OPEN CLOSE
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	// HANDLE FORM AND EDIT STATE
	const [questionForm, setQuestionForm] = useState({});
	const [isEditing, setIsEditing] = useState(false);

	// DEFINITON OF COLUMNS
	const columns = [
		{
			title: "Control",
			dataIndex: "questionName",
			key: "questionName",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "left",
					}}
				>
					<Typography variant="h5">{record.questionName}</Typography>
				</div>
			),
			sorter: (a, b) => a.questionName.localeCompare(b.questionName),
		},
		{
			title: "Gewichtung",
			dataIndex: "questionWeight",
			key: "questionWeight",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "left",
					}}
				>
					<Tag color={record.questionWeight === "P" ? "red" : "orange"}>
						{record.questionWeight === "P"
							? "Pflicht"
							: record.questionWeight}
					</Tag>
				</div>
			),
			// Add sorter if necessary
		},
		{
			title: "S / G",
			dataIndex: "questionType",
			key: "questionType",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "left",
					}}
				>
					<Tag>{record.questionType}</Tag>
				</div>
			),
			// Add sorter if necessary
		},
		{
			title: "Aktionen",
			key: "actions",
			render: (_, record) => (
				<Space size="middle">
					<EditOutlined
						onClick={() => {
							setQuestionForm(record);
							setIsEditing(true);
							handleOpen();
						}}
					/>
					<DeleteOutlined
						onClick={() => {
							Modal.confirm({
								title: `Control "${record.questionName}" wirklich löschen?.`,
								icon: <ExclamationCircleOutlined />,
								onOk: () => {
									deleteQuestionMutation.mutate({
										clusterId: cluster._id,
										questionId: record.id,
									});
								},
							});
						}}
					/>
				</Space>
			),
		},
	];

	// DISPLAY LOADING PAGE
	if (clustersLoading) return <LoadingScreen />;

	// DISPLAY ERROR PAGE
	if (clustersError) return <ErrorScreen />;

	// GET CLUSTER
	const cluster = clusters.find((cluster) => cluster._id == clusterId);

	// TRANSFORM THE DATA FROM THE DATABASE FOR THE DATAGRID
	const transformedQuestions = cluster.clusterQuestions.map((question) => ({
		id: question._id,
		...question,
	}));

	// PROVIDE FILTERED CLUSTERS FOR SEARCH BAR
	const filteredQuestions = () => {
		const byType = unifiedFilter(
			"clusterQuestionsByType",
			transformedQuestions,
			searchTerm,
			questionType
		);
		const byWeight = unifiedFilter(
			"clusterQuestionsByWeight",
			byType,
			searchTerm,
			questionWeight
		);
		return byWeight;
	};

	const breadcrumbItems = [
		{ title: "Dashboard", href: "/" },
		{ title: "Clusters" },
		{ title: cluster.clusterName },
	];

	// RENDER
	return (
		<>
			<Box className="backgroundBox">
				{/* BREADCRUMBS */}
				<Breadcrumb items={breadcrumbItems} />

				<TopBarButton
					headline={cluster.clusterName}
					subheadline="Hier werden alle Controls des jeweiligen Clusters angezeigt."
					btnText="Control anlegen"
					onClick={() => {
						//setIsEditing(false);
						handleOpen();
					}}
				/>

				<Box display="flex" flexDirection="column" flexGrow={1}>
					{/* TABLE */}
					<Table
						title={() => tableHeader()}
						size="small"
						columns={columns}
						dataSource={filteredQuestions()}
						pagination={{ pageSize: 10 }}
					/>
				</Box>

				<QuestionDrawer
					clusterId={cluster._id}
					isEditing={isEditing}
					setIsEditing={setIsEditing}
					initQuestionForm={questionForm}
					open={open}
					onClose={handleClose}
				/>
			</Box>
		</>
	);

	function tableHeader() {
		return (
			<Box
				height="60px"
				px="10px"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box display="flex" flexDirection="row">
					<WeightFilter setQuestionWeight={setQuestionWeight} />
					<Box width={10} />
					<TypeFilter setQuestionType={setQuestionType} />
				</Box>

				<Box display="flex" flexDirection="row">
					<Search
						onChange={handleSearch}
						size="large"
						placeholder="Control suchen ..."
						style={{
							width: 250,
						}}
					/>
				</Box>
			</Box>
		);
	}
}
