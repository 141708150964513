import themeSetting from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import { Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";

/* test test test */
/*
	WORKING INITIAL MVP 24.02.2023 19:10
*/

// CONTEXT
import { UserContextProvider } from "./context/User";
import { ClientsContextProvider } from "./context/Client";
import { AuditsContextProvider } from "./context/Audit";
import { ClustersContextProvider } from "./context/Cluster"
import { TemplatesContextProvider } from "./context/Template"
import ProtectedRoute from "./context/ProtectedRoute";

// GLOBAL
import Sidebar from "./scenes/_Global/Sidebar";

// SCENES
import Header from "./scenes/_Global/Header";
import Dashboard from "./scenes/Dashboard";
import Audits from "./scenes/Audits";
import AuditClusters from "./scenes/AuditClusters";
import AuditQuestions from "./scenes/AuditQuestions";
import CreateAudit from "./scenes/CreateAudit";
import Clients from "./scenes/Clients";
import ClientHistory from "./scenes/ClientHistory";
import Clusters from "./scenes/Clusters";
import Questions from "./scenes/Questions";
import ClusterTemplates from "./scenes/ClusterTemplates";
import CreateTemplate from "./scenes/CreateTemplate";
import TemplateSettings from "./scenes/TemplateSettings";
import Calendar from "./scenes/Calendar";
import AuditSettings from "./scenes/AuditSettings";
import Register from "./scenes/Register";
import Login from "./scenes/Login";
import User from "./scenes/User";
import EmailVerification from "./scenes/EmailVerification";

// DATEPICKER
// date-fns
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

// MAIN
export default function App() {
	// Determine if we are on the login or register page
	const location = useLocation();
	const isAuthPage =
		location.pathname === "/login" ||
		location.pathname === "/register" ||
		location.pathname === "/verify-email-success";

	return (
		<RootProvider>
			{/* Resetting the CSS */}
			<CssBaseline />

			<div className="app">
				{!isAuthPage && <Sidebar />}

				<Toaster
					position="top-center"
					toastOptions={{
						duration: 2000,
						style: {
							padding: "2px",
							color: "black",
							background: "#d5eaf7",
						},
					}}
				/>
				<main className="content">
					{!isAuthPage && <Header />}

					<Routes>
						{/* Display Login page */}
						<Route path="/login" element={<Login />} />

						{/* Display Register page */}
						<Route path="/register" element={<Register />} />

						{/* Display Email Verification page */}
						<Route
							path="/verify-email-success"
							element={<EmailVerification />}
						/>

						{/* Protected Routes */}
						<Route
							path="/"
							element={
								<ProtectedRoute>
									<Dashboard />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/audits"
							element={
								<ProtectedRoute>
									<Audits />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/audits/:auditId/clusters"
							element={
								<ProtectedRoute>
									<AuditClusters />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/audits/:auditId/clusters/:clusterId/questions"
							element={
								<ProtectedRoute>
									<AuditQuestions />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/audits/:auditId/settings"
							element={
								<ProtectedRoute>
									<AuditSettings />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/create-audit"
							element={
								<ProtectedRoute>
									<CreateAudit />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/clients"
							element={
								<ProtectedRoute>
									<Clients />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/clients/:clientId/history"
							element={
								<ProtectedRoute>
									<ClientHistory />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/clusters"
							element={
								<ProtectedRoute>
									<Clusters />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/clusters/:clusterId/questions"
							element={
								<ProtectedRoute>
									<Questions />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/templates"
							element={
								<ProtectedRoute>
									<ClusterTemplates />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/create-template"
							element={
								<ProtectedRoute>
									<CreateTemplate />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/templates/:templateId/settings"
							element={
								<ProtectedRoute>
									<TemplateSettings />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/calendar"
							element={
								<ProtectedRoute>
									<Calendar />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/user"
							element={
								<ProtectedRoute>
									<User />
								</ProtectedRoute>
							}
						/>
					</Routes>
				</main>
			</div>
		</RootProvider>
	);
}

function RootProvider({ children }) {
	return (
		<UserContextProvider>
			<ClientsContextProvider>
				<AuditsContextProvider>
					<ClustersContextProvider>
					<TemplatesContextProvider>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						{/* Providing a theme for colors, could be extended for Buttons and other styles */}
						<ThemeProvider theme={themeSetting}>
							<ConfirmProvider>{children}</ConfirmProvider>
						</ThemeProvider>
					</LocalizationProvider>
					</TemplatesContextProvider>
					</ClustersContextProvider>
				</AuditsContextProvider>
			</ClientsContextProvider>
		</UserContextProvider>
	);
}
