import { useState, useRef, useEffect } from "react";
import { Button, Steps, Breadcrumb, Form } from "antd";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

// COMPONENTS
import LoadingScreem from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";
import SelectClusters from "../../components/SelectClusters";
import TemplateForm from "../../components/TemplateForm";

// LOCAL COMPONENTS
// --

// CONTEXT
import { useTemplates, useUpdateTemplateMutation } from "../../context/Template";

const breadcrumbItems = [
	{ title: "Dashboard", href: "/" },
	{ title: "Vorlage anlegen" },
];

// MAIN
export default function TemplateSettings() {
	// HOOKS
    const { templates, templatesLoading, templatesError } = useTemplates()
	const updateTemplateMutation = useUpdateTemplateMutation();
    const { templateId } = useParams();
    const template = templates.find((template) => template._id == templateId)

	// SELECT CLUSTERS
	const [selectedClusters, setSelectedClusters] = useState([]);
	const [selectedQuestionsInCluster, setSelectedQuestionsInCluster] = useState(
		{}
	);

	// TEMPLATE FORM
	const [form] = Form.useForm();
	const templateFormRef = useRef();

	// STEP HANDLING
	const [current, setCurrent] = useState(0);
	const next = () => {
		setCurrent(current + 1);
	};
	const prev = () => {
		setCurrent(current - 1);
	};

    useEffect(() => {
        if(template) {
            // set form fields
            form.setFieldsValue({
                templateName: template.templateName,
                templateDescription: template.templateDescription,
            })

            // set clusters
            setSelectedClusters(template.auditClusters)
        }
    }, [template])

	// DISPLAY LOADING PAGE
	if (templatesLoading) return <LoadingScreem />;

	// DISPLAY ERROR PAGE
	if (templatesError) return <ErrorScreen />;

    // FORM HANDLING STEP 3
	const onFinish = async (values) => {
		const updatedClusters = selectedClusters.map((cluster) => {
			return {
				_id: cluster._id,
				clusterName: cluster.clusterName,
				clusterQuestions: cluster.clusterQuestions.map((question) => {
					return {
						questionName: question.questionName,
						questionWeight: question.questionWeight,
						questionType: question.questionType,
					};
				}),
			};
		});

		const updatedTemplate = {
			...values,
			auditClusters: updatedClusters, // Use the extracted IDs here
		};

		updateTemplateMutation.mutate({
            templateId: template._id,
            templateData: updatedTemplate,
        });
		console.log("New Template:", updatedTemplate);
	};


	// STEPS
	const steps = [
		{
			title: "Controls und Cluster auswählen",
			content: chooseControls,
		},
		{
			title: "Vorlagen Informationen",
			content: inputInfos,
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	return (
		<>
			<Box className="backgroundBox">
				<Box>
					{/* BREADCRUMBS */}
					<Breadcrumb items={breadcrumbItems} />
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						sx={{
							marginTop: "20px",
							marginBottom: "20px",
						}}
					>
						<Box display="flex" flexDirection="column">
							<Typography variant="h4" fontWeight="bold">
								Vorlage: {template.templateName}
							</Typography>
						</Box>
					</Box>
					<Steps size="small" current={current} items={items} />
					<div style={{ paddingTop: 20 }}>{steps[current].content()}</div>
				</Box>
				<div
					style={{
						marginTop: 24,
					}}
				>
					{current < steps.length - 1 && (
						<Button type="primary" onClick={() => next()}>
							Weiter
						</Button>
					)}
					{current === steps.length - 1 && (
						<Button
							type="primary"
							onClick={() => {
								templateFormRef.current.submit();
							}}
						>
							Abschließen
						</Button>
					)}
					{current > 0 && (
						<Button
							style={{
								margin: "0 8px",
							}}
							onClick={() => prev()}
						>
							Zurück
						</Button>
					)}
				</div>
			</Box>
		</>
	);

	function chooseControls() {
		return (
			<SelectClusters
				selectedClusters={selectedClusters}
				setSelectedClusters={setSelectedClusters}
				selectedQuestionsInCluster={selectedQuestionsInCluster}
				setSelectedQuestionsInCluster={setSelectedQuestionsInCluster}
			/>
		);
	}

	function inputInfos() {
		return (
			<TemplateForm
				form={form}
				templateFormRef={templateFormRef}
				onFinish={onFinish}
			/>
		);
	}
}
