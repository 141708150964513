import { useState } from "react";
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { tokens } from "../../theme";

// CONTEXT & HOOKS
import {
	useUser,
	useUpdateUserPasswordMutation,
	useInit2FAMutation,
} from "../../context/User";

// LOCAL COMPONENTS
import QRModal from "./components/QRModal";

// SCHEMA
import changePasswordSchema from "../../schemas/changePasswordSchema";

// MAIN
export default function Questions() {
	const { user, setUser } = useUser();
	const updateUserPasswordMutation = useUpdateUserPasswordMutation();

	const [qrModalOpen, setQRModalOpen] = useState(false);
	const handleQRModalOpen = () => setQRModalOpen(true);
	const handleQRModalClose = () => setQRModalOpen(false);

	const [otpURL, setOtpURL] = useState("");
	const init2FAMutation = useInit2FAMutation(setOtpURL, handleQRModalOpen);

	const theme = useTheme();
	const colors = tokens(theme.palette);

	const formik = useFormik({
		initialValues: {
			oldPassword: "",
			newPassword: "",
			newPassword2: "",
		},
		validationSchema: changePasswordSchema,
		onSubmit: (values) => {
			updateUserPasswordMutation.mutate({
				...values,
				userEmail: user.userEmail,
			});
		},
	});

	return (
		<>
			<Box className="backgroundBox">
				<Box display="flex" flexDirection="column" justifyContent="start">
					<Typography mt="20px" fontWeight="bold">
						Benutzer
					</Typography>
					<Typography>{user.userName}</Typography>

					<Typography mt="20px" fontWeight="bold">
						E-Mail
					</Typography>
					<Typography>{user.userEmail}</Typography>

					<Typography mt="20px" fontWeight="bold">
						Passwort ändern
					</Typography>
					<form onSubmit={formik.handleSubmit}>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							margin="normal"
							id="oldPassword"
							label="Aktuelles Passwort"
							name="oldPassword"
							type="password"
							value={formik.values.oldPassword}
							onChange={formik.handleChange}
							helperText={formik.errors.oldPassword}
							error={!!formik.errors.oldPassword}
							placeholder="Altes Passwort eingeben"
							sx={{ borderRadius: 1, width: 400 }}
						/>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							margin="normal"
							id="newPassword"
							label="Neues Passwort"
							name="newPassword"
							type="password"
							value={formik.values.newPassword}
							onChange={formik.handleChange}
							helperText={formik.errors.newPassword}
							error={!!formik.errors.newPassword}
							placeholder="Neues Passwort eingeben"
							sx={{ borderRadius: 1, width: 400 }}
						/>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							margin="normal"
							id="newPassword2"
							label="Neues Passwort bestätigen"
							name="newPassword2"
							type="password"
							value={formik.values.newPassword2}
							onChange={formik.handleChange}
							helperText={formik.errors.newPassword2}
							error={!!formik.errors.newPassword2}
							placeholder="Neues Passwort erneut eingeben"
							sx={{ borderRadius: 1, width: 400 }}
						/>
						<Button
							type="submit"
							fullWidth
							size="small"
							variant="contained"
							sx={{
								mt: 3,
								mb: 2,
								borderRadius: 1,
								width: 200,
								backgroundColor: colors.blue,
								boxShadow: "none",
							}}
						>
							Passwort ändern
						</Button>
					</form>


					<Typography mt="20px" fontWeight="bold">
						2FA
					</Typography>
					<Typography
						fontWeight="bold"
						color={
							user.twoFAEnabled == "true" ? colors.green : colors.red
						}
					>
						2FA ist{" "}
						{user.twoFAEnabled == "true" ? "aktiviert" : "deaktiviert"}
					</Typography>
					<Button
						type="button" // this is a button that doesn't submit the form
						fullWidth
						size="small"
						variant="contained"
						onClick={
							user.twoFAEnabled == "false"
								? init2FAMutation.mutate
								: () => {}
						}
						sx={{
							mt: 1,
							mb: 2,
							borderRadius: 1,
							width: 150,
							backgroundColor: colors.blue,
							boxShadow: "none",
						}}
					>
						2FA{" "}
						{user.twoFAEnabled == "false" ? "aktivieren" : "deaktivieren"}
					</Button>
				</Box>
			</Box>

			<QRModal
				open={qrModalOpen}
				handleClose={handleQRModalClose}
				otpURL={otpURL}
				setUser={setUser}
				user={user}
			/>
		</>
	);
}
