import axiosInstance from "./axiosInstance"

/*
  @desc: Get all templates from the database
*/
async function getTemplates() {
  const response = await axiosInstance.get(`/templates`);
  return response.data;
}

/*
  @desc: Create a new template
  @para: data -> { templateData }
*/
async function createTemplate(data) {
  const response = await axiosInstance.post(`/templates`, data);
  return response.data;
}

/*
  @desc: Update a current template
  @para: t -> { templateId, templateData }
*/
async function updateTemplate(t) {
  const response = await axiosInstance.put(`/templates/${t.templateId}`, t.templateData);
  return response.data;
}

/*
  @desc: Delete a template
  @para: id -> { templateId }
*/
async function deleteTemplate(id) {
  const response = await axiosInstance.delete(`/templates/${id}`);
  return response.data;
}

export { getTemplates, createTemplate, updateTemplate, deleteTemplate };
