import * as yup from 'yup';

const changePasswordSchema = yup.object().shape({
    oldPassword: yup.string().required('Aktuelles Passwort ist erforderlich'),
    newPassword: yup.string()
        .required('Passwort ist ein Pflichtfeld')
        .min(8, 'Mindestens 8 Zeichen')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.,\-_+])/, 
            "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten!"
        ),
    newPassword2: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'Passwörter müssen übereinstimmen')
        .required('Passwortbestätigung ist erforderlich')
});

export default changePasswordSchema;
