import { Form, Select, DatePicker, Input } from "antd";

// COMPONENTS
import LoadingScreem from "../components/LoadingScreen";
import ErrorScreen from "../components/ErrorScreen";

// CONTEXT
import { useUser } from "../context/User"
import { useClients } from "../context/Client";

export default function AuditForm({ form, auditFormRef, onFinish }) {
	// HOOKS
	const { clients, clientsLoading, clientsError } = useClients();
	const { user } = useUser()

	// JSX COMPONENTS
	const { Option } = Select;

	// DISPLAY LOADING PAGE
	if (clientsLoading) return <LoadingScreem />;

	// DISPLAY ERROR PAGE
	if (clientsError) return <ErrorScreen />;

	return (
		<div style={{ textAlign: "start" }}>
			<Form
				form={form}
				ref={auditFormRef}
				onFinish={onFinish}
				name="audit_formular"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ width: 500 }}
			>
				<Form.Item
					name="auditName"
					label="Audit Name"
					rules={[
						{
							required: true,
							message: "Bitte geben Sie den Audit-Namen ein!",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="auditClient"
					label="Audit Kunde"
					rules={[
						{
							required: true,
							message: "Bitte wählen Sie den Audit-Kunden aus!",
						},
					]}
				>
					<Select placeholder="Kunde auswählen">
						{clients.map((client, index) => (
							<Option key={index} value={client._id}>
								{client.clientName}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					name="auditKeyPerson"
					label="Verantwortliche Person"
					rules={[
						{
							required: true,
							message: "Bitte geben Sie die verantwortliche Person ein!",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="auditAuditor"
					label="Auditor"
					initialValue={user.userName ? user.userName : ""}
					rules={[
						{
							required: true,
							message: "Bitte geben Sie den Prüfer ein!",
						},
					]}
				>
					<Input disabled />
				</Form.Item>

				<Form.Item
					name="auditCarryOut"
					label="Durchführen am"
					rules={[
						{
							required: true,
							message: "Bitte ein Datum setzen!",
						},
					]}
				>
					<DatePicker format="YYYY-MM-DD" />
				</Form.Item>

				<Form.Item
					name="auditPassingThreshold"
					label="Bestehende Schwelle"
					rules={[
						{
							required: true,
							message: "Bitte geben Sie die bestehende Schwelle ein!",
						},
						{
							type: "number",
							min: 0,
							max: 100,
							transform: (value) => Number(value),
							message:
								"Die bestehende Schwelle sollte zwischen 0 und 100 liegen!",
						},
					]}
				>
					<Input min={0} max={100} style={{ width: "100%" }} />
				</Form.Item>

				<Form.Item
					name="auditCycle"
					label="Audit Zyklus"
					rules={[
						{
							required: true,
							message: "Bitte geben Sie den Audit-Zyklus ein!",
						},
						{
							type: "number",
							min: 0,
							max: 12,
							transform: (value) => Number(value),
							message:
								"Bitte geben Sie eine gültige Nummer (0-12) für den Audit-Zyklus ein!",
						},
					]}
				>
					<Input style={{ width: "100%" }} />
				</Form.Item>
			</Form>
		</div>
	);
}
