import { useState } from "react";
import { Box, Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { Table, Tag, Input, Breadcrumb, List } from "antd";

// COMPONENTS
import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";
import TopBarButton from "../_Global/TopBarButton";
import { QuestionFilter } from "../../components/Filter";

// LOCAL COMPONENTS
import QuestionForm from "./components/QuestionForm";

// CONTEXT
import { useAudits } from "../../context/Audit";

// UTILS
import {
	countToDosForQuestion, 
	unifiedFilter,
} from "../../utils/Audit";
import { getRightDateFormat } from "../../utils/Date";

// MAIN
export default function AuditQuestions() {
	// CONTEXT
	const { audits, auditsLoading, auditsError } = useAudits();

	const [questionStatus, setQuestionStatus] = useState("a");

	// SEARCH
	const { Search } = Input;
	const [searchValue, setSearchValue] = useState("");
	const handleSearch = (e) => {
		setSearchValue(e.target.value);
	};

	// GET ID FROM URL
	const { auditId, clusterId } = useParams();

	// handle data grid selection
	const [selected, setSelected] = useState([]);

	// HANDLE MODEL OPEN
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	// DEFINE COLUMNS
	const columns = [
		{
			title: "Control",
			dataIndex: "questionName",
			key: "questionName",
			sorter: (a, b) => a.questionName.localeCompare(b.questionName),
		},
		{
			title: "S / A",
			dataIndex: "questionType",
			key: "questionType",
			width: "7%",
			render: (_, record) => (
				<Tag>{record.questionType == "S" ? "Spezifisch" : "Allgemein"}</Tag>
			),
			sorter: (a, b) => a.questionType.localeCompare(b.questionType),
		},
		{
			title: "Gewichtung",
			dataIndex: "questionWeight",
			key: "questionWeight",
			width: "7%",
			render: (_, record) => (
				<Tag color={record.questionWeight == "P" ? "red" : "orange"}>
					{record.questionWeight == "P"
						? "Pflicht"
						: record.questionWeight}
				</Tag>
			),
			sorter: (a, b) => a.questionWeight - b.questionWeight,
		},
		{
			title: "Kunde Notiz",
			dataIndex: "questionClientNote",
			key: "questionClientNote",
			width: "15%",
			sorter: (a, b) =>
				a.questionClientNote.localeCompare(b.questionClientNote),
		},
		{
			title: "Auditor Notiz",
			dataIndex: "questionAuditorNote",
			key: "questionAuditorNote",
			width: "15%",
			sorter: (a, b) =>
				a.questionAuditorNote.localeCompare(b.questionAuditorNote),
		},
		{
			title: "Status",
			dataIndex: "questionIsDone",
			key: "questionIsDone",
			render: (_, record) =>
				record.questionIsDone == "true" ? (
					<Tag color="green">Abgschlossen</Tag>
				) : (
					<Tag color="red">Offen</Tag>
				),
			sorter: (a, b) => a.questionIsDone - b.questionIsDone,
		},
		{
			title: "ToDos",
			dataIndex: "countOpenToDos",
			key: "countOpenToDos",
			width: "7%",
			render: (_, record) =>
				record.countOpenToDos !== 0 && <Tag>{record.countOpenToDos}</Tag>,
			sorter: (a, b) => a.countOpenToDos - b.countOpenToDos,
		},
	];

	// DISPLAY LOADING PAGE
	if (auditsLoading) return <LoadingScreen />;

	// DISPLAY ERROR PAGE
	if (auditsError) return <ErrorScreen />;

	// FILTER FOR SPECIFIC AUDIT
	const audit = audits.find((audit) => audit._id === auditId);
	const cluster = audit.auditClusters.find(
		(cluster) => cluster._id === clusterId
	);

	// TRANSFORM QUESTIONS FOR TABLE
	const transformedQuestions = cluster.clusterQuestions.map((question) => {
		return {
			id: question._id,
			questionIsDone: question.questionIsDone,
			questionName: question.questionName,
			questionType: question.questionType,
			questionWeight: question.questionWeight,
			questionAuditorNote: question.questionAuditorNote,
			questionClientNote: question.questionClientNote,
			countOpenToDos: countToDosForQuestion(question, "false"),
			questionToDos: question.questionToDos,
		};
	});

	// KEY IS NEEDED FOR CHECKBOX TO WORK
	const dataSource = transformedQuestions.map((question) => ({
		...question,
		key: question.id,
	}));

	const filteredQuestions = unifiedFilter("questions", dataSource, searchValue, questionStatus)

	// rowSelection object indicates the need for row selection
	const rowSelection = {
		onChange: (_, selectedRows) => {
			setSelected(selectedRows);
		},
		getCheckboxProps: (record) => ({
			name: record.id,
		}),
	};

	// EXPANDABLE ROW RENDER FOR TODOS
	const expandedRowRender = (record) => {
		const openToDos = record.questionToDos.filter(
			(todo) => todo.todoIsDone !== "true"
		);
		if (openToDos.length === 0) return null;

		return (
			<List
				itemLayout="horizontal"
				dataSource={openToDos}
				renderItem={(todo) => (
					<List.Item>
						<List.Item.Meta
							style={{ paddingLeft: 20 }}
							title={`ToDo: ${todo.todoTitle}`}
							description={
								<>
									Fällig: {getRightDateFormat(todo.todoDueAt)}
									<br />
									Verantwortlicher: {todo.todoKeyPerson}
								</>
							}
						/>
					</List.Item>
				)}
			/>
		);
	};

	const breadcrumbItems = [
		{ title: 'Dashboard', href: '/' },
		{ title: 'Audits', href: "/audits" },
		{ title: audit.auditName, href: `/audits/${audit._id}/clusters` },
		{ title: cluster.clusterName },
	  ];

	return (
		<>
			<Box className="backgroundBox">
				{/* BREADCRUMBS */}
				<Breadcrumb items={breadcrumbItems} />

				<TopBarButton
					headline={cluster.clusterName}
					subheadline="Übersicht über alle Controls des Clusters"
					btnText="Control bearbeiten"
					onClick={() => handleOpen()}
				/>

				<Box display="flex" flexDirection="column" flexGrow={1}>
					{/* TABLE */}
					<Table
						title={() => tableHeader()}
						size="small"
						columns={columns}
						dataSource={filteredQuestions}
						rowSelection={{
							type: "checkbox",
							...rowSelection,
						}}
						expandable={{
							expandedRowRender: expandedRowRender,
							rowExpandable: (record) => record.countOpenToDos > 0,
						}}
						pagination={{ pageSize: 100 }}
					/>
				</Box>
			</Box>

			{/* MODAL: EDIT QUESTIONS*/}
			<Modal open={open} onClose={handleClose}>
				<Box className="modalStyle" width="70%">
					<QuestionForm
						questions={
							selected.length === 0 ? transformedQuestions : selected
						}
						handleClose={handleClose}
					/>
				</Box>
			</Modal>
		</>
	);

	function tableHeader() {
		return (
			<Box
				height="60px"
				px="10px"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<QuestionFilter cluster={cluster} setQuestionStatus={setQuestionStatus} />

				<Search
					onChange={handleSearch}
					size="large"
					placeholder="Frage / ToDo suchen ..."
					style={{
						width: 250,
					}}
				/>
			</Box>
		);
	}
}
