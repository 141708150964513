import { useEffect, useState } from "react";
import {
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	useTheme,
	Box,
	TextField,
	Button,
} from "@mui/material";
import { tokens } from "../../../theme";
import { Formik, FieldArray, Form } from "formik";
import { useParams } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";

import Checkbox from "../../../components/Checkbox"

// ICONS
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";

// API
import { useMutation, useQueryClient } from "react-query";
import { updateAuditQuestion } from "../../../api/auditQuestionService";

const textFieldStyle = {
	mb: "10px",
	
	color: "black",
	".MuiFormLabel-root": {
		color: "black",
	},
	".MuiInputBase-input": {
		color: "white",
		WebkitTextFillColor: "black !important",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#666666",
		},
		"&:hover fieldset": {
			borderColor: "white",
		},
		"&.Mui-focused fieldset": {
			borderColor: "black",
		},
		// Add style for disabled state
		// Add style for disabled state
		"&.Mui-disabled": {
			WebkitTextFillColor: "gray !important",
			opacity: 0.5,
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "gray",
			},
		},
	},
};

export default function QuestionForm({ questions, handleClose }) {
	// GET THEME AND COLORS
	const theme = useTheme();
	const colors = tokens(theme.palette);

	const confirm = useConfirm();

	// GET CLUSTERID FROM URL
	let { auditId, clusterId } = useParams();

	/* HANDLE STEPPER: START */
	const [activeStep, setActiveStep] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState({});
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	useEffect(() => {
		if (activeStep === 0) {
			setCurrentQuestion(questions[activeStep]);
		}
	}, [questions]);

	// DEFINE INITIAL VALUES
	const initialValues = {
		id: currentQuestion.id,
		questionName: currentQuestion.questionName,
		questionIsDone: currentQuestion.questionIsDone
			? currentQuestion.questionIsDone
			: "false",
		questionWeight: currentQuestion.questionWeight
			? currentQuestion.questionWeight
			: "1",
		questionType: currentQuestion.questionType
			? currentQuestion.questionType
			: "S",
		questionAuditorNote: currentQuestion.questionAuditorNote
			? currentQuestion.questionAuditorNote
			: "",
		questionClientNote: currentQuestion.questionClientNote
			? currentQuestion.questionClientNote
			: "",
		questionToDos: currentQuestion.questionToDos
			? currentQuestion.questionToDos
			: [],
	};

	// HANDLE API CALL
	const queryClient = useQueryClient();
	// : UPDATE
	const updateQuestionMutation = useMutation(updateAuditQuestion, {
		onSuccess: () => {
			queryClient.invalidateQueries(["audits"]);
		},
	});

	return (
		<Box display="flex" flexDirection="column">
			<Formik
				initialValues={initialValues}
				onSubmit={async (values, { setValues }) => {
					updateQuestionMutation.mutate({
						auditId: auditId,
						clusterId: clusterId,
						questionId: values.id,
						questionData: {
							questionIsDone: values.questionIsDone,
							questionWeight: values.questionWeight,
							questionType: values.questionType,
							questionToDos: values.questionToDos,
							questionAuditorNote: values.questionAuditorNote,
							questionClientNote: values.questionClientNote,
						},
					});
					if (activeStep === questions.length - 1) {
						handleClose();
					} else {
						setValues(questions[activeStep + 1]); // Update the form values with the next question
						handleNext(); // Update the active step
					}
				}}
				enableReinitialize={true}
			>
				{(props) => (
					<Form>
						<Typography
							width="80%"
							ml="60px"
							mb="40px"
							mt="20px"
							variant="h3"
							fontWeight="bold"
							sx={{ color: colors.black }}
						>
							{props.values.questionName}
						</Typography>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="space-between"
							height="530px"
							borderRadius="20px"
							p="20px"
							sx={{ backgroundColor: colors.white }}
						>
							<Box
								display="flex"
								flexDirection="row"
								justifyContent="space-between"
							>
								{/* LEFT SIDE */}
								<Box
									display="flex"
									flexDirection="column"
									sx={{ width: "30%", color: colors.white }}
								>
									<SubHeader title="Status:" />
									<ToggleButtonGroup
										value={props.values.questionIsDone}
										exclusive
										onChange={(event, value) =>
											props.setFieldValue("questionIsDone", value)
										}
										sx={{ mb: "15px", height: "30px" }}
									>
										{withCustomToggleButton(ToggleButton, {
											value: "false",
											title: "Fehlt",
											color: colors.red,
										})}
										{withCustomToggleButton(ToggleButton, {
											value: "true",
											title: "Umgesetzt",
											color: colors.green,
										})}
									</ToggleButtonGroup>
									<SubHeader title="Typ:" />
									<ToggleButtonGroup
										value={props.values.questionType}
										exclusive
										onChange={(event, value) =>
											props.setFieldValue("questionType", value)
										}
										sx={{ mb: "15px", height: "30px" }}
									>
										{withCustomToggleButton(ToggleButton, {
											value: "S",
											title: "Spezifisch",
											color: colors.purple,
										})}
										{withCustomToggleButton(ToggleButton, {
											value: "G",
											title: "Allgemein",
											color: colors.blue,
										})}
									</ToggleButtonGroup>
									<SubHeader title="Gewichtung:" />
									<ToggleButtonGroup
										value={props.values.questionWeight}
										exclusive
										onChange={(event, value) =>
											props.setFieldValue("questionWeight", value)
										}
										sx={{ mb: "15px", height: "30px" }}
									>
										{withCustomToggleButton(ToggleButton, {
											value: "1",
											title: "1",
											color: colors.orange,
										})}
										{withCustomToggleButton(ToggleButton, {
											value: "2",
											title: "2",
											color: colors.orange,
										})}
										{withCustomToggleButton(ToggleButton, {
											value: "3",
											title: "3",
											color: colors.orange,
										})}
										{withCustomToggleButton(ToggleButton, {
											value: "4",
											title: "4",
											color: colors.orange,
										})}
										{withCustomToggleButton(ToggleButton, {
											value: "5",
											title: "5",
											color: colors.orange,
										})}
										{withCustomToggleButton(ToggleButton, {
											value: "P",
											title: "P",
											color: colors.red,
										})}
										{withCustomToggleButton(ToggleButton, {
											value: "N",
											title: "N",
											color: colors.grey,
										})}
									</ToggleButtonGroup>
									<SubHeader title="Auditor-Notiz:" />
									<TextField
										type="text"
										name="questionAuditorNote"
										value={props.values.questionAuditorNote}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										multiline={true}
										minRows={2}
										sx={textFieldStyle}
									/>
									<SubHeader title="Kunden-Notiz" />
									<TextField
										type="text"
										name="questionClientNote"
										value={props.values.questionClientNote}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										multiline={true}
										minRows={2}
										sx={textFieldStyle}
									/>
								</Box>

								{/* RIGHT SIDE */}
								<Box
									ml="30px"
									display="flex"
									flexDirection="column"
									flex={1}
								>
									{/* DISPLAY ALL TODOS  */}
									<FieldArray name="questionToDos">
										{({ insert, remove, push }) => (
											<Box>
												<Box
													display="flex"
													flexDirection="row"
													justifyContent="space-between"
													mb="15px"
												>
													<SubHeader title="ToDos" />
													<Button
														onClick={() =>
															push({
																todoTitle: "",
																todoKeyPerson: "",
																todoDueAt: "2022-01-01",
																todoIsDone: "false",
															})
														}
														sx={{
															px: "20px",
															height: "25px",
															textTransform: "none",
															color: colors.white,
															backgroundColor: colors.blue,
														}}
													>
														+ ToDo Hinzufügen
													</Button>
												</Box>

												<Box
													display="flex"
													flexDirection="row"
													justifyContent="space-between"
													mb="15px"
													pl="50px"
												>
													<SubHeader title="ToDo:" />
													<SubHeader title="Verantwortlicher" />
													<SubHeader title="Fälligkeit" />
												</Box>

												<Box>
													{props.values.questionToDos.length > 0 &&
														props.values.questionToDos.map(
															(todo, index) => (
																<Box
																	display="flex"
																	alignItems="center"
																	key={index}
																	py="1px"
																>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon
																				sx={{
																					color: colors.midgrey,
																				}}
																			/>
																		}
																		checkedIcon={
																			<CheckBoxIcon
																				sx={{
																					color: colors.yellow,
																				}}
																			/>
																		}
																		checked={
																			todo.todoIsDone ===
																			"true"
																		}
																		onChange={(event) => {
																			const newTodoIsDone =
																				event.target
																					.checked;
																			props.setFieldValue(
																				`questionToDos.${index}.todoIsDone`,
																				newTodoIsDone
																					? "true"
																					: "false"
																			);
																		}}
																	/>
																	<TextField
																		disabled={
																			todo.todoIsDone ===
																			"true"
																				? true
																				: false
																		}
																		name={`questionToDos.${index}.todoTitle`}
																		value={todo.todoTitle}
																		onChange={
																			props.handleChange
																		}
																		type="text"
																		size="small"
																		sx={{...textFieldStyle,
																			width: "50%", mr:"5px"}
																		}
																	/>

																	{/* VERANTOWRTLICHER TEXTFELD */}
																	<TextField
																		disabled={
																			todo.todoIsDone ===
																			"true"
																				? true
																				: false
																		}
																		name={`questionToDos.${index}.todoKeyPerson`}
																		value={todo.todoKeyPerson}
																		onChange={
																			props.handleChange
																		}
																		type="text"
																		size="small"
																		sx={{
																			...textFieldStyle,
																			width: "30%", mr:"5px"
																		}}
																	/>

																	{/* FÄLLIGKEIT TEXTFELD */}
																	<TextField
																		disabled={
																			todo.todoIsDone ===
																			"true"
																				? true
																				: false
																		}
																		name={`questionToDos.${index}.todoDueAt`}
																		value={todo.todoDueAt}
																		onChange={
																			props.handleChange
																		}
																		type="date"
																		size="small"
																		sx={{
																			...textFieldStyle,
																			width: "30%"
																		}}
																	/>
																	
																	<Button
																		onClick={() => {
																			confirm({
																				description: `Achtung dieses ToDo wird unwiederruflich gelöscht.`,
																			})
																				.then(() => {
																					remove();
																				})
																				.catch(() =>
																					console.log(
																						"cancel..."
																					)
																				);
																		}}
																		sx={{
																			textTransform: "none",
																			color: colors.midgrey,
																		}}
																	>
																		<DeleteIcon />
																	</Button>
																</Box>
															)
														)}
												</Box>
											</Box>
										)}
									</FieldArray>
								</Box>
							</Box>

							{/* BUTTONS FOR STEPPER NAVIGATION*/}
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									pt: 2,
								}}
							>
								<Button
									className="navBtnStyle"
									onClick={
										activeStep === 0
											? () => {
													handleClose();
											  }
											: () => {
													handleBack();
													props.setValues(
														questions[activeStep - 1]
													);
											  }
									}
									variant="outlined"
								>
									{activeStep === 0 ? "Abbrechen" : "Zurück"}
								</Button>

								{activeStep !== questions.length - 1 && (
									<Button
										className="navBtnStyle"
										type="submit"
										variant="outlined"
										sx={{ width: "250px !important" }}
									>
										{questions.length === 1
											? "Sichern & Abschließen"
											: "Sichern & Weiter"}
									</Button>
								)}

								{activeStep === questions.length - 1 && (
									<Button
										className="navBtnStyle"
										type="submit"
										variant="outlined"
										sx={{ width: "250px !important" }}
									>
										Sichern & Abschließen
									</Button>
								)}
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
}

const withCustomToggleButton = (Component, { value, title, color }) => {
	return (
		<Component
			value={value}
			sx={{
				px: "15px",
				textTransform: "none",
				color: "white",
				backgroundColor: "#787878",
				"&.Mui-selected": {
					backgroundColor: color,
					color: "white",
				},
			}}
		>
			{title}
		</Component>
	);
};

const SubHeader = ({ title }) => {
	return (
		<Typography variant="h6" fontWeight="bold">
			{title}
		</Typography>
	);
};
