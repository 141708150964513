import { useState } from "react";
import { Collapse, List, Checkbox, Tag, Input } from "antd";
import { Box } from "@mui/material";

// COMPONENTS
import LoadingScreem from "../components/LoadingScreen";
import ErrorScreen from "../components/ErrorScreen";
import { TypeFilter, WeightFilter } from "../components/Filter";

// CONTEXT
import { useClusters } from "../context/Cluster";

// UTILS
import {
	toggleAllQuestionsInCluster,
	toggleQuestionInCluster,
    unifiedFilter
} from "../utils/Audit";

export default function SelectClusters({
	selectedClusters,
	setSelectedClusters,
	selectedQuestionsInCluster,
	setSelectedQuestionsInCluster,
}) {
	// CONTEXT
	const { clusters, clustersLoading, clustersError } = useClusters();

    // RADIO AND SEARCH
    const [questionWeight, setQuestionWeight] = useState("a"); // default all
	const [questionType, setQuestionType] = useState("a"); // default all
	const [searchTerm, setSearchTerm] = useState("");
	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

    const { Panel } = Collapse;
	const { Search } = Input;


	// DISPLAY LOADING PAGE
	if (clustersLoading) return <LoadingScreem />;

	// DISPLAY ERROR PAGE
	if (clustersError) return <ErrorScreen />;

    const filterClusterQuestions = (clusterQuestions) => {
		const byType = unifiedFilter(
			"clusterQuestionsByType",
			clusterQuestions,
			searchTerm,
			questionType
		);
		const byWeight = unifiedFilter(
			"clusterQuestionsByWeight",
			byType,
			searchTerm,
			questionWeight
		);
		return byWeight;
	};
        
	return (
		<>
			{/* Header content */}
			{mainHeader()}

			<Collapse bordered={true}>
				{clusters.map((cluster) => {
					const filteredQuestions = filterClusterQuestions(
						cluster.clusterQuestions
					);

					if (filteredQuestions.length === 0) return null; // Don't show cluster with no matching questions

					return (
						<Panel header={clusterHeader(cluster)} key={cluster._id}>
							<Checkbox.Group
								value={selectedQuestionsInCluster[cluster._id] || []}
								onChange={(checkedValues) => {
									setSelectedQuestionsInCluster((prev) => ({
										...prev,
										[cluster._id]: checkedValues,
									}));
								}}
							>
								<List
									dataSource={filteredQuestions}
									renderItem={(question) =>
										questionRow(cluster, question)
									}
								/>
							</Checkbox.Group>
						</Panel>
					);
				})}
			</Collapse>
		</>
	);

    // DISPLAY MAIN HEADER: FILTER AND SEARCH
    function mainHeader() {
		return (
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				sx={{ backgroundColor: "white", padding: 2 }}
			>
				<Box display="flex" flexDirection="row">
					<WeightFilter setQuestionWeight={setQuestionWeight} />
					<Box width={10} />
					<TypeFilter setQuestionType={setQuestionType} />
				</Box>

				<Search
					size="large"
					placeholder="Control suchen"
					onChange={handleSearch}
					style={{ width: 300 }}
				/>
			</Box>
		);
	}

    // DISPLAY THE CLUSTER: NAME AND ON CONDITION THE COUNT OF SELECTED CLUSTERS
	function clusterHeader(cluster) {
		const isClusterSelected = selectedClusters.some(c => c._id === cluster._id);
		
		return (
			<Box display="flex" flexDirection="row" justifyContent="space-between">
				<Checkbox
					checked={isClusterSelected}
					onChange={() =>
						toggleAllQuestionsInCluster(
							cluster,
							setSelectedClusters,
							setSelectedQuestionsInCluster
						)
					}
				>
					{cluster.clusterName}
				</Checkbox>
				{isClusterSelected ? (
					<Box display="flex">
						{selectedClusters.find(c => c._id === cluster._id).clusterQuestions.length}/
						{cluster.clusterQuestions.length}
					</Box>
				) : (
					<></>
				)}
			</Box>
		);
	}

    // DISPLAY THE QUESTION NAME: AND PARAMETERS
	function questionRow(cluster, question) {
		const isQuestionSelected = selectedClusters.some(
			c => c._id === cluster._id && c.clusterQuestions.some(q => q._id === question._id)
		);
	
		return (
			<List.Item key={question._id}>
				<Checkbox
					value={question._id}
					checked={isQuestionSelected}
					onChange={() => {
						toggleQuestionInCluster(
							cluster,
							question,
							setSelectedClusters
						);
					}}
				>
					{question.questionName}
				</Checkbox>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
				>
					<Box display="flex" flexDirection="row" marginLeft={5}>
						<Tag
							color={question.questionWeight === "P" ? "red" : "orange"}
						>
							{question.questionWeight}
						</Tag>
						<Tag>{question.questionType}</Tag>
					</Box>
				</Box>
			</List.Item>
		);
	}	
}
