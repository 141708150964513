import React from "react";
import { useTheme } from "@mui/material";
import { Drawer, Card, Tag } from "antd";
import { Box, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";

// COMPONENTS
import LoadingScreen from "../../../components/LoadingScreen";

// CONTEXT
import { useAudits } from "../../../context/Audit";
import { useUser } from "../../../context/User"

// UTILS
import { getRightDateFormat } from "../../../utils/Date"

export default function MainDrawer({ toggleDrawer, open }) {
	const { audits, auditsLoading, auditsError } = useAudits();
    const { user, loading } =  useUser()

	const navigate = useNavigate();

	// GET THEME AND COLORS
	const theme = useTheme();
	const colors = tokens(theme.palette);

	// DISPLAY LOADING PAGE
	if (auditsLoading || loading) return <LoadingScreen />;

	// DISPLAY ERROR PAGE
	if (auditsError) return <Typography color={colors.lightgrey}>error</Typography>;

	function getAllToDos() {
        const todosNotification = [];
        
        for (const audit of audits) {
            // Check if the audit.auditAuditor contains the current user's userName
            if (user.userName.includes(audit.auditAuditor)) {
                for (const cluster of audit.auditClusters) {
                    for (const question of cluster.clusterQuestions) { // Fixed typo 'quesiton' to 'question'
                        for (const todo of question.questionToDos) {
                            if (todo.todoIsDone == "false") {
                                todosNotification.push({
                                    todo: todo,
                                    client: audit.auditClient.clientName,
                                    question: question.questionName,
                                    link: `/audits/${audit._id}/clusters/${cluster._id}/questions`,
                                });
                            }
                        }
                    }
                }
            }
        }
    
        // Sort the ToDos by todoDueAt
        todosNotification.sort((a, b) => new Date(a.todo.todoDueAt) - new Date(b.todo.todoDueAt));
    
        return todosNotification;
    }

	return (
		<Drawer
			title={`ToDos für ${user.userName}`}
			placement="right"
			closable={true}
			onClose={toggleDrawer}
			open={open}
			style={{ backgroundColor: colors.verylightblue }}
			width="30%"
		>
			{getAllToDos().map((todoNotification, index) => (
				<ToDoCard todoNotification={todoNotification} key={index} />
			))}
		</Drawer>
	);

	function ToDoCard({ todoNotification }) {

        const getTagColor = () => {
            const today = new Date();
            const dueDate = new Date(todoNotification.todo.todoDueAt);
            const oneWeekFromNow = new Date(today).setDate(today.getDate() + 7);
            const twoWeeksFromNow = new Date(today).setDate(today.getDate() + 14);
    
            if (dueDate < today) {
                return 'red';
            } else if (dueDate >= today && dueDate <= oneWeekFromNow) {
                return 'yellow';
            } else if (dueDate > oneWeekFromNow && dueDate <= twoWeeksFromNow) {
                return 'gray';
            } else {
                return 'gray';  // default value
            }
        };
    
        const getTagLabel = (color) => {
            switch(color) {
                case 'red': return 'Überfällig';
                case 'yellow': return 'Bald fällig';
                case 'gray': return 'In der Zukunft';
                default: return '';
            }
        };
    
        const tagColor = getTagColor();
        const tagLabel = getTagLabel(tagColor);
    
        return (
            <Card
                hoverable
                bordered={false}
                style={{ marginBottom: 10 }}
                onClick={() => navigate(todoNotification.link)}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    justifyContent="start"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6" color={colors.midgrey}>
                            Kunde: {todoNotification.client}
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                            {getRightDateFormat(todoNotification.todo.todoDueAt)}
                        </Typography>
                    </Box>
    
                    <Typography variant="h5" fontWeight="bold">
                        ToDo: {todoNotification.todo.todoTitle}
                    </Typography>
    
                    <Typography variant="h6" color={colors.midgrey}>
                        {todoNotification.question}
                    </Typography>
                </Box>

                <Tag color={tagColor}>{tagLabel}</Tag>
            </Card>
        );
    }    
}