import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import { QueryClient, QueryClientProvider } from "react-query";

const meta = {
	title: "revisior",
	description: "I am a description, and I can create multiple tags",
};

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<DocumentMeta {...meta}>
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</BrowserRouter>
	</DocumentMeta>
);
