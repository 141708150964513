import { useEffect } from "react";
import { Button, Col, Drawer, Form, Input, Row, Space } from "antd";

// CONTEXT & HOOKS
import {
  useCreateClusterMutation,
  useUpdateClusterMutation,
} from "../../../context/Cluster";

// MAIN
export default function ClusterDrawer({
  isEditing,
  setIsEditing,
  initClusterForm,
  onClose,
  open,
}) {
  const [form] = Form.useForm();
  const createClusterMutation = useCreateClusterMutation();
  const updateClusterMutation = useUpdateClusterMutation();

  const { TextArea } = Input;

  // LOAD FIELD VALUES WHEN CALLED
  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue(initClusterForm);
    } else {
      form.resetFields();
    }
  }, [isEditing]);

  const onSubmit = async (values) => {
    if (isEditing) {
      updateClusterMutation.mutate({
        clusterId: initClusterForm.id,
        clusterData: values,
      });
    } else {
      createClusterMutation.mutate(values);
    }

    form.resetFields();
    setIsEditing(false);
    onClose();
  };

  return (
    <Drawer
      title="Neues Cluster hinzufügen"
      width={420}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <Space style={{ float: "right" }}>
          <Button onClick={onClose}>Abbrechen</Button>
          <Button type="primary" onClick={() => form.submit()}>
            Absenden
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="clusterName"
              label="Clustername"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den Clustername ein",
                },
              ]}
            >
              <Input placeholder="Bitte geben Sie den Clustername ein" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="clusterDescription"
              label="Beschreibung"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie die Beschreibung ein",
                },
              ]}
            >
              <TextArea placeholder="Bitte geben Sie die Beschreibung ein" rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="clusterReference"
              label="Referenz"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie die Referenz ein",
                },
              ]}
            >
              <Input placeholder="Bitte geben Sie die Referenz ein" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
