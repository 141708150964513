import { Button, Dropdown } from "antd";
import { DownOutlined, DownloadOutlined } from "@ant-design/icons";

// API
import { getAuditReport } from "../api/auditService";

export default function DownloadReport({ auditId }) {

    const items = [
        {
            key: "1",
            label: (
                <a onClick={() => handleDownload("pdf") }>
                    PDF-Datei
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a onClick={() => handleDownload("csv") }>
                    CSV-Datei
                </a>
            ),
        },
    ];

    const handleDownload = async (format) => {
        try {
            const { data, fileExtension } = await getAuditReport(auditId, format);
            const fileURL = URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = `Audit_Report${fileExtension}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(`Error downloading the ${format.toUpperCase()}:`, error);
        }
    };

    return (
        <Dropdown menu={{items}} placement="bottomRight">
            <Button size="large">
				<DownloadOutlined />
                Report herunterladen
                <DownOutlined />
            </Button>
        </Dropdown>
    );
}