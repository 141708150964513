import { useQuery } from "react-query";
import { createContext, useContext } from "react";
import { getTemplates } from "../../api/templateService";

export const TemplatesContext = createContext({});

export function TemplatesContextProvider({ children }) {
	const { data: templates, isLoading, error } = useQuery("templates", getTemplates);

	return (
		<TemplatesContext.Provider value={{ templates, templatesLoading: isLoading, templatesError: error }}>
			{children}
		</TemplatesContext.Provider>
	);
}

export function useTemplates() {
    return useContext(TemplatesContext);
}