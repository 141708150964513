import axiosInstance from "./axiosInstance"

/*
  @desc: Get all audits from the database
*/
async function getAudits() {
  const response = await axiosInstance.get(`/audits`);
  return response.data;
}

/*
  @desc: Create a new audit
  @para: data -> { auditData }
*/
async function createAudit(data) {
  const response = await axiosInstance.post(`/audits`, data);
  return response.data;
}

/*
  @desc: Update a current audit
  @para: a -> { auditId, auditData }
*/
async function updateAudit(a) {
  console.log(a)
  const response = await axiosInstance.put(`/audits/${a.auditId}`, a.auditData);
  return response.data;
}

/*
  @desc: Delete an audit
  @para: auditId
*/
async function deleteAudit(auditId) {
  const response = await axiosInstance.delete(`/audits/${auditId}`);
  return response.data;
}

/*
  @desc: Get a PDF report from an audit
*/
async function getAuditReport(auditId, format) {
  try {
      let endpoint;
      let responseType;
      let fileExtension;

      if (format === 'pdf') {
          endpoint = `/audits/${auditId}/pdf`;
          responseType = 'blob';
          fileExtension = '.pdf';
      } else if (format === 'csv') {
          endpoint = `/audits/${auditId}/csv`;
          responseType = 'blob';  // CSV is treated similarly
          fileExtension = '.csv';
      } else {
          throw new Error('Invalid format specified');
      }

      const response = await axiosInstance.get(endpoint, { responseType });
      return { data: response.data, fileExtension };
  } catch (error) {
      console.error(`Error fetching ${format.toUpperCase()}:`, error);
  }
}
export { getAudits, createAudit, updateAudit, deleteAudit, getAuditReport };