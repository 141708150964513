import { useState } from "react";
import { useFormik } from "formik";
import { Box, TextField, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// API
import { useMutation, useQueryClient } from "react-query";
import { loginUser } from "../../api/authService";
import TwoFA from "./components/TwoFA";

// CONTEXT
import { useUser } from "../../context/User";

// SCHEMA
import loginSchema from "../../schemas/loginSchema";

export default function Login() {
	const { setUser } = useUser();
	const navigate = useNavigate();

	const [requires2FA, setRequires2FA] = useState(false);

	const [twoFAModalOpen, setTwoFAModalOpen] = useState(false);
	const handleTwoFAModalOpen = () => setTwoFAModalOpen(true);
	const handleTwoFAModalClose = () => setTwoFAModalOpen(false);

	const theme = useTheme();
	const colors = tokens(theme.palette);

	const queryClient = useQueryClient();

	const createUserMutation = useMutation(loginUser, {
		onSuccess: (data) => {
			console.log(data);
			if (data.message == "requiresEmailVerification") {
				toast.error("Bitte E-Mail bestätigen.");
			} else if (data.message == "requires2FA") {
				setRequires2FA(true);
				setTwoFAModalOpen(true);
			} else {
				toast.success(`Hallo ${data.userName}`);
				setUser(data);
				queryClient.invalidateQueries("users");
				setTimeout(() => navigate("/"), 1000);
			}
		},
		onError: (error) => {
			const errorMessage =
				error.response?.data?.message ||
				"Ein unbekannter Fehler ist aufgetreten";
			toast.error(errorMessage);
		},
	});

	const formik = useFormik({
		initialValues: {
			userEmail: "",
			userPassword: "",
		},
		validationSchema: loginSchema,
		onSubmit: (values) => {
			createUserMutation.mutate(values);
		},
	});

	return (
		<Box marginLeft="-200px" display="flex" flex="1" backgroundColor={colors.lightblue}>
			<Box
				m="25px"
				display="flex"
				flex="1"
				flexDirection="row"
				backgroundColor={colors.white}
			>
				<Box
					pt="90px"
					pl="30px"
					pr="70px"
					display="flex"
					flexDirection="column"
					alignItems="left"
					justifyContent="center"
					flex="0.3"
					backgroundColor={colors.white}
				>
					<Box
						mb="80px"
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<img
							alt="logo"
							src="../../assets/logo_secusentry_p.png"
							width="60px"
							height="60px"
						/>
						<Typography
							variant="h2"
							sx={{ fontWeight: "bold", pl: "5px" }}
						>
							revisior
						</Typography>
					</Box>
					<Typography mb="10px" variant="h4" fontWeight="bold">
						Login
					</Typography>
					<form onSubmit={formik.handleSubmit}>
						<TextField
							fullWidth
							margin="normal"
							id="userEmail"
							label="Email"
							name="userEmail"
							value={formik.values.userEmail}
							onChange={formik.handleChange}
							error={
								formik.touched.userEmail &&
								Boolean(formik.errors.userEmail)
							}
							helperText={
								formik.touched.userEmail && formik.errors.userEmail
							}
							placeholder="Email eingeben"
							sx={{ my: 1 }}
						/>
						<TextField
							fullWidth
							variant="outlined"
							margin="normal"
							id="userPassword"
							label="Passwort"
							name="userPassword"
							type="password"
							value={formik.values.userPassword}
							onChange={formik.handleChange}
							error={
								formik.touched.userPassword &&
								Boolean(formik.errors.userPassword)
							}
							helperText={
								formik.touched.userPassword &&
								formik.errors.userPassword
							}
							placeholder="Passwort eingeben"
							sx={{ my: 1 }}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								mt: 1,
								mb: 2,
								borderRadius: 1,
								height: 40,
								backgroundColor: colors.black85,
							}}
						>
							Login
						</Button>
						<Typography variant="h5">
							Noch nicht registriert?{" "}
							<Link to="/register" sx={{ color: colors.black85 }}>
								Registrieren
							</Link>
						</Typography>
					</form>

					{requires2FA && (
						<TwoFA
							open={twoFAModalOpen}
							handleClose={handleTwoFAModalClose}
							setUser={setUser}
							values={formik.values}
						/>
					)}
				</Box>

				<Box pb="25px" display="flex" flex="0.7" flexDirection="column">
					<Box
						display="flex"
						flex="0.3"
						flexDirection="column"
						backgroundColor="white"
						zIndex={1}
					>
						<Typography pt="100px" variant="h1" fontSize="45px" color={colors.black85}>
							Mit Sicherheit im Blick:
						</Typography>
						<Typography pb="20px" variant="h1" fontSize="55px" fontWeight="bold" color={colors.black85}>
							ZukunftsfesteAudits.
						</Typography>
					</Box>
					<Box
						style={{
							backgroundImage: 'url("../../assets/loginpicture.jpg")',
							backgroundSize: "cover",
							backgroundPosition: "center",
							width: "100%",
							height: "100%",
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
}
