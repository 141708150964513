import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { Typography, Box } from "@mui/material";

// ICONS
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function getItem(label, key, icon) {
	return {
		key,
		icon,
		label,
	};
}

const items = [
	getItem("Dashboard", "", <HomeOutlinedIcon />),
	getItem("Audits", "audits", <AnalyticsOutlinedIcon />),
	getItem("Kunden", "clients", <AccountCircleOutlinedIcon />),
	getItem("Clusters", "clusters", <FolderOpenOutlinedIcon />),
	getItem("Vorlagen", "templates", <ArticleIcon />),
	getItem("Kalender", "calendar", <CalendarMonthIcon />),
];

export default function Sidebar() {

	const [selected, setSelected] = useState("dashboard");

	return (
		<Menu
			defaultSelectedKeys={[selected]}
			mode="inline"
			theme="dark"
			onClick={({ key }) => setSelected(key)}
			style={{ width: "200px", position: "fixed", top: "0px", height: "100vh" }}
		>
			{/* LOGO */}
			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				my="10px"
				ml="10px"
			>
				<img
					alt="logo"
					src="../../assets/logo_secusentry_no_shadow.png"
					width="50px"
					height="50px"
				/>

				<Typography
					variant="h3"
					color="white"
					sx={{ fontWeight: "bold", pl: "5px" }}
				>
					revisior
				</Typography>
			</Box>
			{items.map((item) => (
				<Menu.Item key={item.key} icon={item.icon}>
					<Link to={`/${item.key}`}>{item.label}</Link>
				</Menu.Item>
			))}
		</Menu>
	);
}
