import axiosInstance from "./axiosInstance"

/*
  @desc: Register a new user
  @para: data -> { userData }
*/
async function registerUser(data) {
  const response = await axiosInstance.post(`/auth/register`, data);
  return response.data;
}

/*
  @desc: Register a new user
  @para: data -> { userData }
*/
async function loginUser(data) {
  const response = await axiosInstance.post(`/auth/login`, data);
  return response.data;
}

/*
  @desc: Change the password of a user
  @para: data -> { userData }
*/
async function changePassword(data) {
  const response = await axiosInstance.post(`/auth/profile/change-password`, data);
  console.log(response.data)
  return response.data;
}

/*
  @desc: Logout the current user
*/
async function logoutUser() {
  const response = await axiosInstance.post('/auth/logout');
  return response.data;
}

/*
  @desc: Generate the 2FA Secret for a user
*/
async function generate2FASecret() {
  const response = await axiosInstance.post(`/auth/otp/generate`);
  return response.data;
}

/*
  @desc: Verfiy the code from the 2FA app
*/
async function verify2FASecret(code) {
  const response = await axiosInstance.post(`/auth/otp/verify`, code);
  return response.data;
}

/*
  @desc: Validate the code from the 2FA app
*/
async function validate2FACode(code) {
  const response = await axiosInstance.post(`/auth/otp/validate`, code);
  return response.data;
}

export { registerUser, loginUser, changePassword, logoutUser, generate2FASecret, verify2FASecret, validate2FACode }