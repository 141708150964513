import axiosInstance from "../../api/axiosInstance";
import { createContext, useState, useEffect, useContext } from "react";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
        if (!user) {
            axiosInstance
                .get("/auth/profile")
                .then(({ data }) => {
                    setUser(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching profile:", error);
                    setLoading(false);
                });
        }
    }, [user]);
    

	return (
		<UserContext.Provider value={{ user, setUser, loading }}>
			{children}
		</UserContext.Provider>
	);
}

export function useUser() {
    return useContext(UserContext);
}
