import { Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import { useNavigate } from "react-router-dom";

// MAIN
export default function CellButtonNavigate({to, text}) {

    const navigate = useNavigate()

    // GET THEME AND COLORS
    const theme = useTheme()
    const colors = tokens(theme.palette)

	return (
        <Button
            variant="outlined"
            onClick={() => navigate(to, {state: {clusterName: text}})}
            sx={{
                textAlign:"left",
                textTransform: 'capitalize',
                backgroundColor: "transparent",
                borderColor: "transparent",
                justifyContent: "left",
                borderRadius: "30px",
                boxShadow: "none",
                transition:"none",
                px:"20px ",
                
                "&:hover": {
                    backgroundColor:"transparent",
                    border: 1,
                    borderColor: colors.black,
                    borderRadius: "10px",
                },
            }}
        >
            <Typography 
            variant="h5" 
            fontWeight="600"
            sx={{ color: colors.black }}   
            >
                {text}
            </Typography>
        </Button>
	);
}