import { useMutation, useQueryClient } from "react-query";
import { createTemplate, updateTemplate, deleteTemplate } from "../../api/templateService"; // Assuming the path to the services
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";


// CREATE TEMPLATE
export function useCreateTemplateMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate()

    return useMutation(createTemplate, {
        onSuccess: () => {
            toast.success("Template erfolgreich erstellt");
            queryClient.invalidateQueries("templates");
            navigate("/templates") 
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}

// UPDATE TEMPLATE
export function useUpdateTemplateMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate()

    return useMutation(updateTemplate, {
        onSuccess: () => {
            toast.success("Template erfolgreich aktualisiert");
            queryClient.invalidateQueries("templates");
            navigate("/templates") 
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}

// DELETE TEMPLATE
export function useDeleteTemplateMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(deleteTemplate, {
        onSuccess: () => {
            toast.success("Template erfolgreich gelöscht");
            queryClient.invalidateQueries("templates");
            navigate("/templates");
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}
