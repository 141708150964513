import { Dropdown, Button } from "antd";
import {
    SettingOutlined,
    LogoutOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

// CONTEXT AND HOOKS
import { useLogoutUserMutation } from "../../../context/User"

// MAIN
export default function AccountMenu(props) {
    const logoutMutation = useLogoutUserMutation();

    const navigate = useNavigate()

    const handleLogout = () => {
        logoutMutation.mutate(); // call this when you want to trigger the logout action
    };

    const menuItems = [
        {
            key: 'settings',
            label: (
                <a onClick={() => navigate('/user')}>
                    <SettingOutlined /> Einstellungen
                </a>
            ),
        },
        {
            key: 'teams',
            label: (
                <a onClick={() => {}}>
                    <TeamOutlined /> Teams
                </a>
            ),
        },
        {
            key: 'logout',
            label: (
                <a onClick={() => handleLogout()}>
                    <LogoutOutlined /> Logout
                </a>
            ),
        }
    ];

    return (
        <Dropdown
            menu={{ items: menuItems }}
            placement="bottomRight"
        >
            <Button style={{ backgroundColor: "transparent", borderRadius: "10px", marginLeft: "10px" }}>
                {props.userName}
            </Button>
        </Dropdown>
    );
}
