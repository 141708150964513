import { createTheme } from "@mui/material/styles";

// color design tokens

export const tokens = () => ({
    lightgrey: "#ddd",
    midgrey: "#555",
    grey: "#212121",
    lightred: "#ff304f",
    red: "#ea0047",
    green: "#5bba30",
    orange: "#ffaf38",
    blue: "#1777ff",
    purple: "#B49BFF",
    white: "#fff",
    black: "#000",
    black85: "#262626",
    blackblue: "#001628",

    lightblue: "#d5eaf7",
    verylightblue: "#f2f6fc",
})

// mui theme settings
export const themeSettings = () => {
    const colors = tokens()
 
    return {
        palette: {
            primary: {
                main: colors.midgrey,
            },
            secondary: {
                main: colors.midgrey,
            },
            neutral: {
                dark: colors.midgrey,
                main: colors.midgrey,
                light: colors.midgrey,
            },
            background: {
                default: "#000",
            }
        },
        typography: {
            fontFamily: ["Helvetica", "sans-serif"].join(","),
            fontSize: 14,
            h1: {
                fontFamily: ["Helvetica", "sans-serif"].join(","),
                fontSize: 34,
                color: colors.black
            },
            h2: {
                fontFamily: ["Helvetica", "sans-serif"].join(","),
                fontSize: 28,
                color: colors.black
            },
            h3: {
                fontFamily: ["Helvetica", "sans-serif"].join(","),
                fontSize: 24,
                color: colors.black
            },
            h4: {
                fontFamily: ["Helvetica", "sans-serif"].join(","),
                fontSize: 18,
                color: colors.black
            },
            h5: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 16,
                color: colors.black
            },
            h6: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 14,
                color: colors.black
            },
        },
        components: {
            MuiSelect: {
              styleOverrides: {
                icon: {
                  color: 'black', // set the color of the select icon to pink
                },
              },
            },
          },
    }
}

const theme = createTheme(themeSettings());

export default theme;