import axiosInstance from "./axiosInstance"

/*
  @desc: Get all clients from the database
*/
async function getClients() {
  const response = await axiosInstance.get(`/clients`);
  return response.data;
}

/*
  @desc: Create a new client
  @para: data -> { clientData }
*/
async function createClient(data) {
  const response = await axiosInstance.post(`/clients`, data);
  return response.data;
}

/*
  @desc: Update a current client
  @para: c -> { clientId, clientData }
*/
async function updateClient(c) {
  const response = await axiosInstance.put(`/clients/${c.clientId}`, c.clientData);
  return response.data;
}

/*
  @desc: Delete a client
  @para: id -> clientId
*/
async function deleteClient(id) {
  const response = await axiosInstance.delete(`/clients/${id}`);
  return response.data;
}

export { getClients, createClient, updateClient, deleteClient };