import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';

// CONTEXT AND HOOKS
import { useValidate2FAMutation } from "../../../context/User"

function TwoFA({ open, handleClose, values }) {
    const validate2FAMutation = useValidate2FAMutation(handleClose);

    const [code, setCode] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {  // regex to check if it's a number
            setCode(value);
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ width: 500, padding: 2, m: 'auto', bgcolor: 'background.paper', borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Zwei-Faktor-Authentifizierung
                </Typography>
                <Box my={2}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Authentifizierungscode"
                        value={code}
                        onChange={handleInputChange}
                        placeholder="Geben Sie den Code ein"
                        inputProps={{ maxLength: 6 }}
                    />
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button variant="contained" color="secondary" onClick={handleClose}>
                        Abbrechen
                    </Button>
                    <Button variant="contained" color="primary" disabled={code.length != 6} onClick={() => {
                        // TODO: Validate the code here
                        validate2FAMutation.mutate({ code: code, userEmail: values.userEmail, userPassword: values.userPassword })
                    }}>
                        Bestätigen
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default TwoFA;
