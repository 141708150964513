import { Row } from "antd";
import { useNavigate } from "react-router-dom";

// COMPONENTS
import TopBarButton from "../_Global/TopBarButton";
import TemplateCards from "../../components/TemplateCards";

// MAIN
export default function ClusterTemplates() {
	const navigate = useNavigate();

	return (
		<>
			<Row className="backgroundBox" style={{ justifyContent: "start" }}>
				<TopBarButton
					headline="Alle Audit Vorlagen"
					subheadline="Übersicht über alle Vorlagen für das schnelle Anlegen von Audits"
					btnText="Vorlage anlegen"
					onClick={() => navigate("/create-template")}
				/>
				<TemplateCards type="clusters" />
			</Row>
		</>
	);
}
