import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";

export default function ErrorScreen() {
	// GET THEME AND COLORS
	const theme = useTheme();
	const colors = tokens(theme.palette);

	return (
		<Box className="backgroundBox" justifyContent="center">
            <Typography variant="h1" fontWeight="bold" width="100%" textAlign="center">
                Server is currently down. We are working on it sorry.
            </Typography>
		</Box>
	);
}
