import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

export default function TopBarDropdown({ headline, subheadline, items }) {
	const theme = useTheme();
	const colors = tokens(theme.palette);

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<Box display="flex" flexDirection="column">
				<Typography variant="h4" fontWeight="bold">
					{headline}
				</Typography>
				<Typography variant="h6" color={colors.midgrey}>
					{subheadline}
				</Typography>
			</Box>

			<Dropdown menu={{ items }} placement="bottomRight">
				<Button type="primary" size="large">
					Optionen
					<DownOutlined />
				</Button>
			</Dropdown>
		</Box>
	);
}
