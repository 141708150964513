import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { useMutation, useQueryClient } from "react-query";
import { toast } from 'react-hot-toast';

// API
import { verify2FASecret } from "../../../api/authService"

function QRModal({ open, handleClose, otpURL, setUser, user }) {

  const [code, setCode] = useState('');

  const queryClient = useQueryClient();
  const verify2FAMutation = useMutation(verify2FASecret, {
    onSuccess: (data) => {
        console.log(user)
        setUser(prev => ({...prev, twoFAEnabled: "true", twoFAVerified: "true"}))
        console.log(user)
        handleClose()
        toast.success(data.message)
    },
    onError: (error) => {
        toast.error('Fehler beim Initiieren von 2FA: ' + error.message);
    },
})

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {  // regex to check if it's a number
      setCode(value);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ width: 500, padding: 2, m: 'auto', bgcolor: 'background.paper', borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          Zwei-Faktor-Authentifizierung einrichten
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Scannen Sie den untenstehenden QR-Code mit einer OTP-App wie Microsoft Authenticator oder Authy.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. Geben Sie den generierten Code in das Bestätigungsfeld ein, um die Einrichtung abzuschließen.
        </Typography>
        <Box display="flex" justifyContent="center" my={2}>
          <img src={otpURL} alt="QR Code" />
        </Box>
        <Box my={2}>
          <TextField
            autoFocus
            variant="outlined"
            fullWidth
            label="Authentifizierungscode"
            value={code}
            onChange={handleInputChange}
            placeholder="Geben Sie den Code ein"
            inputProps={{ maxLength: 6 }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" disabled={code.length != 6} onClick={() => {
            // TODO: Validate the code here
            verify2FAMutation.mutate({code: code})
          }}>
            Bestätigen
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default QRModal;
