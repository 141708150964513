import { useFormik } from "formik";
import { Box, TextField, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link, useNavigate } from "react-router-dom";

// CONTEXT & HOOKS
import { useCreateUserMutation } from "../../context/User"

// SCHEMA
import registerSchema from "../../schemas/registerSchema"; // Pfad zu deinem Yup-Schema

export default function Register() {
	const createUserMutation = useCreateUserMutation()
	const navigate = useNavigate();
	const theme = useTheme();
	const colors = tokens(theme.palette);

	const formik = useFormik({
		initialValues: {
			userName: "",
			userEmail: "",
			userPassword: "",
			userPassword2: "",
		},
		validationSchema: registerSchema,
		onSubmit: (values) => {
			createUserMutation.mutate(values);
		},
	});

	return (
		<Box marginLeft="-200px" display="flex" flex="1" backgroundColor={colors.lightblue}>
			<Box
				m="25px"
				display="flex"
				flex="1"
				flexDirection="row"
				backgroundColor={colors.white}
			>
				<Box
					pt="90px"
					pl="30px"
					pr="70px"
					display="flex"
					flexDirection="column"
					alignItems="left"
					justifyContent="center"
					flex="0.3"
					backgroundColor={colors.white}
				>
					<Box
						mb="80px"
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<img
							alt="logo"
							src="../../assets/logo_secusentry_p.png"
							width="60px"
							height="60px"
						/>
						<Typography
							variant="h2"
							sx={{ fontWeight: "bold", pl: "5px" }}
						>
							revisior
						</Typography>
					</Box>
					<Typography mb="10px" variant="h4" fontWeight="bold">
						Registrieren
					</Typography>
					<form onSubmit={formik.handleSubmit}>
						<TextField
							fullWidth
							variant="outlined"
							margin="normal"
							id="userName"
							label="Name"
							name="userName"
							autoComplete="off"
							autoFocus
							value={formik.values.userName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								formik.touched.userName &&
								Boolean(formik.errors.userName)
							}
							helperText={
								formik.touched.userName && formik.errors.userName
							}
							placeholder="Name eingeben"
							sx={{ my: 1 }}
						/>
						<TextField
							fullWidth
							variant="outlined"
							margin="normal"
							id="userEmail"
							label="Email"
							name="userEmail"
							autoComplete="off"
							value={formik.values.userEmail}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								formik.touched.userEmail &&
								Boolean(formik.errors.userEmail)
							}
							helperText={
								formik.touched.userEmail && formik.errors.userEmail
							}
							placeholder="Email eingeben"
							sx={{ my: 1 }}
						/>
						<TextField
							fullWidth
							variant="outlined"
							margin="normal"
							id="userPassword"
							label="Passwort"
							name="userPassword"
							type="password"
							autoComplete="off"
							value={formik.values.userPassword}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								formik.touched.userPassword &&
								Boolean(formik.errors.userPassword)
							}
							helperText={
								formik.touched.userPassword &&
								formik.errors.userPassword
							}
							placeholder="Passwort eingeben"
							sx={{ my: 1 }}
						/>
						<TextField
							fullWidth
							variant="outlined"
							margin="normal"
							id="userPassword2"
							label="Passwort Bestätigung"
							name="userPassword2"
							type="password"
							autoComplete="off"
							value={formik.values.userPassword2}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								formik.touched.userPassword2 &&
								Boolean(formik.errors.userPassword2)
							}
							helperText={
								formik.touched.userPassword2 &&
								formik.errors.userPassword2
							}
							placeholder="Passwort Bestätigung eingeben"
							sx={{ my: 1 }}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								mt: 1,
								mb: 2,
								backgroundColor: colors.black85,
							}}
						>
							Registrieren
						</Button>
						<Typography variant="h5">
						Bereits registriert?{" "}
						<Link to="/login" sx={{ color: colors.black85 }}>
							Login
						</Link>
					</Typography>
					</form>
				</Box>
				<Box pb="25px" display="flex" flex="0.7" flexDirection="column">
					<Box
						display="flex"
						flex="0.3"
						flexDirection="column"
						backgroundColor="white"
						zIndex={1}
					>
						<Typography pt="100px" variant="h1" fontSize="45px" color={colors.black85}>
							Mit Sicherheit im Blick:
						</Typography>
						<Typography pb="20px" variant="h1" fontSize="55px" fontWeight="bold" color={colors.black85}>
							ZukunftsfesteAudits.
						</Typography>
					</Box>
					<Box
						style={{
							backgroundImage: 'url("../../assets/loginpicture.jpg")',
							backgroundSize: "cover",
							backgroundPosition: "center",
							width: "100%",
							height: "100%",
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
}
