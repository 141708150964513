// QuestionDrawer.jsx
import React, { useEffect } from "react";
import { Button, Col, Drawer, Form, Input, Row, Space, Radio } from "antd";

// CONTEXT & HOOKS
import {
	useCreateQuestionMutation,
	useUpdateQuestionMutation,
} from "../../../context/Question";

export default function QuestionDrawer({
	clusterId,
	isEditing,
	setIsEditing,
	initQuestionForm,
	onClose,
	open,
}) {
	const [form] = Form.useForm();
	const createQuestionMutation = useCreateQuestionMutation();
	const updateQuestionMutation = useUpdateQuestionMutation();

	const { TextArea } = Input;

	useEffect(() => {

        console.log(initQuestionForm)

		if (isEditing) {
			form.setFieldsValue(initQuestionForm);
		} else {
			form.resetFields();
		}
	}, [isEditing]);

	const onSubmit = async (values) => {

        console.log(values)
        console.log(clusterId)

		if (isEditing) {
			updateQuestionMutation({
				questionId: initQuestionForm.id,
                clusterId: clusterId,
				questionData: values,
			});
		} else {
			createQuestionMutation.mutate({
                clusterId: clusterId,
                questionData: values
            });
		}

		form.resetFields();
		setIsEditing(false);
		onClose();
	};

	return (
		<Drawer
			title="Neues Control hinzufügen"
			width={420}
			onClose={onClose}
			open={open}
			bodyStyle={{ paddingBottom: 80 }}
			footer={
				<Space style={{ float: "right" }}>
					<Button onClick={onClose}>Abbrechen</Button>
					<Button type="primary" onClick={() => form.submit()}>
						Absenden
					</Button>
				</Space>
			}
		>
			<Form layout="vertical" form={form} onFinish={onSubmit}>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="questionName"
							label="Control-Name"
							rules={[
								{
									required: true,
									message:
										"Bitte geben Sie den Namen des Controls ein",
								},
							]}
						>
							<TextArea
								rows={4}
								placeholder="Bitte geben Sie den Namen des Controls ein"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item name="questionWeight" label="Control-Gewichtung">
							<Radio.Group defaultValue="1">
								<Radio.Button value="1">1</Radio.Button>
								<Radio.Button value="2">2</Radio.Button>
								<Radio.Button value="3">3</Radio.Button>
								<Radio.Button value="4">4</Radio.Button>
								<Radio.Button value="5">5</Radio.Button>
								<Radio.Button value="P">P</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item name="questionType" label="Control-Typ">
							<Radio.Group defaultValue="G">
								<Radio.Button value="S">S</Radio.Button>
								<Radio.Button value="G">G</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
}
