import * as yup from "yup";

// VALIDATE REGISTER FORM
const registerSchema = yup.object().shape({
    userName: yup
        .string()
        .required("Name ist ein Pflichtfeld")
        .min(3, "Mindestens 3 Zeichen")
        .max(50, "Maximal 50 Zeichen")
        .matches(/^[a-zA-Z\säöüÄÖÜß]+$/, "Keine Sonderzeichen oder Zahlen!"),
    userEmail: yup
        .string()
        .required("Email ist ein Pflichtfeld")
        .email("Ungültige E-Mail-Adresse"),
    userPassword: yup
        .string()
        .required("Passwort ist ein Pflichtfeld")
        .min(8, "Mindestens 8 Zeichen")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.,\-_+])/, 
            "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten!"
        ),
    userPassword2: yup
        .string()
        .oneOf([yup.ref('userPassword'), null], "Passwörter stimmen nicht überein")
        .required("Passwort Bestätigung ist ein Pflichtfeld"),
});

export default registerSchema;
