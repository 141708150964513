// AUDIT ---------------------------------

// COUNT AUDITS BY STATUS
export function countAudits(audits, status) {
    let count = 0;
    for (const audit of audits) {
        if (audit.auditIsDone === status) {
            count = count + 1;
        }
    }
    return count;
}

// COUNT TODOS BY STATUS
export function countToDosForAudit(audit, status) {
    let count = 0;
    for (const cluster of audit.auditClusters) {
        for (const question of cluster.clusterQuestions) {
            for (const todo of question.questionToDos) {
                if (todo.todoIsDone === status) {
                    count = count + 1;
                }
            }
        }
    }
    return count;
}

// CLUSTER ---------------------------------

// COUNT TODOS FOR CLUSTER
export function countToDosForCluster(cluster) {
    let count = 0;
    for (const question of cluster.clusterQuestions) {
        for (const todo of question.questionToDos) {
            if (todo.todoIsDone === "false") {
                count = count + 1;
            }
        }
    }
    return count;
}

// COUNT QUESTIONS FOR CLUSTER BY STATUS
export function countQuestionsForCluster(cluster, status) {
    let count = 0;
    for (const question of cluster.clusterQuestions) {
        if (question.questionIsDone === status) {
            count = count + 1;
        }
    }
    return count;
}

// QUESTION ---------------------------------

// COUNT TODOS FOR QUESTION
export function countToDosForQuestion(question, status) {
    let count = 0;
    for (const todo of question.questionToDos) {
        if (todo.todoIsDone === status) {
            count = count + 1;
        }
    }
    return count;
}

// CLIENT --------------------------------- 

// COUNT AUDITS FOR CLIENT
export function countAuditsForClient(audits, forClient, status) {
    const filteredAudits = audits.filter(
        (audit) => audit.auditClient.clientName === forClient && audit.auditIsDone === status
    );

    return filteredAudits.length;
}

// COUNT TODOS FOR CLIENT
export function countToDosForClient(audits, forClient, status) {
    const filteredAudits = audits.filter(
        (audit) => audit.auditClient.clientName === forClient
    );

    let count = 0;
    for (const audit of filteredAudits) {
        for (const cluster of audit.auditClusters) {
            for (const question of cluster.clusterQuestions) {
                for (const todo of question.questionToDos) {
                    if (todo.todoIsDone === status) {
                        count = count + 1;
                    }
                }
            }
        }
    }

    return count;
}

