import { useQuery } from "react-query";
import { createContext, useContext } from "react";
import { getClients } from "../../api/clientService";
import { useUser } from "../User"

export const ClientsContext = createContext({});

export function ClientsContextProvider({ children }) {
	const { data: clients, isLoading, error } = useQuery("clients", getClients);
	const { user, loading } = useUser()

	let filteredClients = []

	if (user && clients) {
		filteredClients = clients.filter((client) => {
			if(user && user.userEmail === "revisior@axpy.de") {
				return client.clientKeyPerson === "Max Mustermann"
			}
			return clients
		})
	}

	return (
		<ClientsContext.Provider value={{ clients: filteredClients, clientLoading: isLoading, clientError: error }}>
			{children}
		</ClientsContext.Provider>
	);
}

export function useClients() {
    return useContext(ClientsContext);
}