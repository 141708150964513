import { Navigate } from "react-router-dom";
import { useUser } from "./User";

export default function ProtectedRoute({ children }) {
	const { user, loading } = useUser();

	if (loading) {
		console.log("Still loading user data...");
		return null;
	}
	if (!user) {
		console.log("No user found. Redirecting to login...");
		return <Navigate to="/login" replace />;
	}
	console.log("User found. Rendering protected route.");
	return children;
}
