import { useState, useRef } from "react";
import {
	Button,
	Steps,
	Breadcrumb,
	Form,
} from "antd";
import { Box, Typography } from "@mui/material";

// COMPONENTS
import SelectClusters from "../../components/SelectClusters";
import AuditForm from "../../components/AuditForm";
import TemplateCards from "../../components/TemplateCards";

// LOCAL COMPONENTS
// --

// CONTEXT
import { useCreateAuditMutation } from "../../context/Audit";

const breadcrumbItems = [
	{ title: "Dashboard", href: "/" },
	{ title: "Audit anlegen" },
];

// MAIN
export default function CreateAudit() {
	// HOOKS
	const createAuditMutation = useCreateAuditMutation();

	// SELECT CLUSTERS
	const [selectedClusters, setSelectedClusters] = useState([]);
	const [selectedQuestionsInCluster, setSelectedQuestionsInCluster] = useState(
		{}
	);

	// AUDIT FORM
	const [form] = Form.useForm();
	const auditFormRef = useRef();

	// STEP HANDLING
	const [current, setCurrent] = useState(0);
	const next = () => {
		setCurrent(current + 1);
	};
	const prev = () => {
		setCurrent(current - 1);
	};

	// FORM HANDLING STEP 3
	const onFinish = async (values) => {

		console.log("date: ", values.auditCarryOut)

		// change date format from date picker
		if (values.auditCarryOut) {
			values.auditCarryOut = values.auditCarryOut.format("YYYY-MM-DD");
		}

		console.log("/create-audit: Selected Clusters: ", selectedClusters)

		// set clusters format
		// ADDING ADDITIONAL FIELDS
		const updatedClusters = selectedClusters.map((cluster) => {
			return {
				_id: cluster._id,
				clusterName: cluster.clusterName,
				clusterQuestions: cluster.clusterQuestions.map((question) => {
					return {
						questionName: question.questionName,
						questionWeight: question.questionWeight,
						questionType: question.questionType,
						questionClientNote: "",
						questionAuditorNote: "",
						questionIsDone: "false",
						questionToDos: [],
					};
				}),
			};
		});

		const newAudit = {
			...values,
			auditIsDone: "false",
			auditClusters: updatedClusters, // Use the extracted IDs here
		};

		createAuditMutation.mutate(newAudit);
		console.log("New Audit: ", newAudit);
		form.resetFields()
	};

	// STEPS
	const steps = [
		{
			title: "Vorlage wählen",
			content: chooseTemplate,
		},
		{
			title: "Controls und Cluster auswählen",
			content: chooseControls,
		},
		{
			title: "Allgemeine Informationen",
			content: inputInfos,
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	return (
		<>
			<Box className="backgroundBox">
				<Box>
					{/* BREADCRUMBS */}
					<Breadcrumb items={breadcrumbItems} />
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						sx={{
							marginTop: "20px",
							marginBottom: "20px",
						}}
					>
						<Box display="flex" flexDirection="column">
							<Typography variant="h4" fontWeight="bold">
								Neues Audit anlegen
							</Typography>
						</Box>
					</Box>
					<Steps size="small" current={current} items={items} />
					<div style={{ paddingTop: 20 }}>{steps[current].content()}</div>
				</Box>
				<div
					style={{
						marginTop: 24,
					}}
				>
					{current < steps.length - 1 && (
						<Button type="primary" onClick={() => {
								next()
							}}>
							Weiter
						</Button>
					)}
					{current === steps.length - 1 && (
						<Button
							type="primary"
							onClick={() => {
								auditFormRef.current.submit();
							}}
						>
							Abschließen
						</Button>
					)}
					{current > 0 && (
						<Button
							style={{
								margin: "0 8px",
							}}
							onClick={() => prev()}
						>
							Zurück
						</Button>
					)}
				</div>
			</Box>
		</>
	);

	function chooseTemplate() {
		return (
			<TemplateCards type="createAudit" setSelectedClusters={setSelectedClusters} next={next} />
		)
	}

	function chooseControls() {
		return (
			<SelectClusters
				selectedClusters={selectedClusters}
				setSelectedClusters={setSelectedClusters}
				selectedQuestionsInCluster={selectedQuestionsInCluster}
				setSelectedQuestionsInCluster={setSelectedQuestionsInCluster}
			/>
		);
	}

	function inputInfos() {
		return (
			<AuditForm
				form={form}
				auditFormRef={auditFormRef}
				onFinish={onFinish}
			/>
		);
	}
}
