import { Radio } from "antd";

export function WeightFilter({setQuestionWeight}) {

	return (
		<Radio.Group
			size="large"
			defaultValue="a"
			onChange={(e) => setQuestionWeight(e.target.value)}
		>
			<Radio.Button value="a">Alle</Radio.Button>
			<Radio.Button value="P">Pflicht</Radio.Button>
			<Radio.Button value="1">1</Radio.Button>
			<Radio.Button value="2">2</Radio.Button>
			<Radio.Button value="3">3</Radio.Button>
			<Radio.Button value="4">4</Radio.Button>
			<Radio.Button value="5">5</Radio.Button>
		</Radio.Group>
	);
}
