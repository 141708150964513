import { Radio } from "antd";

// UTILS
import {
	countAudits,
} from "../../utils/Audit";

export function AuditFilter({audits, setAuditStatus}) {    

	return (
		<Radio.Group
			defaultValue="a"
			onChange={(e) => setAuditStatus(e.target.value)}
			size="large"
		>
			<Radio.Button value="a">Alle anzeigen</Radio.Button>
			<Radio.Button value="false">
				Offen({countAudits(audits, "false")})
			</Radio.Button>
			<Radio.Button value="true">
				Erledigt({countAudits(audits, "true")})
			</Radio.Button>
		</Radio.Group>
	);
}
