import { useState } from "react";
import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { tokens } from "../../theme";
import { Button, Space } from "antd";
import { BellOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// CONTEXT & HOOKS
import { useUser } from "../../context/User";

// LOCAL COMPONENTS
import AccountMenu from "./components/AccountMenu";
import MainDrawer from "./components/MainDrawer";

export default function Header() {
    const { user } = useUser()

    const theme = useTheme();
    const colors = tokens(theme.palette);

    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
            sx={{
                p: "20px",
                height: "60px",
                backgroundColor: colors.verylightblue,
                borderBottom: `1px solid ${colors.lightgrey}`,
            }}
        >
            <Space>
                <Button 
                    icon={<BellOutlined />} 
                    type="text"
                    onClick={() => toggleDrawer()}
                />
                <Button 
                    icon={<QuestionCircleOutlined />} 
                    type="text"
                />
            </Space>

            {!!user && <AccountMenu userName={user.userName} />}

            <MainDrawer toggleDrawer={toggleDrawer} open={open}  />

        </Box>
    );
}
