import { Radio } from "antd";

// UTILS
import {
	countQuestionsForCluster,
} from "../../utils/Audit";

export function QuestionFilter({cluster, setQuestionStatus}) {
	return (
		<Radio.Group
			defaultValue="a"
			size="large"
			onChange={(e) => setQuestionStatus(e.target.value)}
		>
			<Radio.Button value="a">Alle Controls</Radio.Button>
			<Radio.Button value="false">
				Offen ({countQuestionsForCluster(cluster, "false")})
			</Radio.Button>
			<Radio.Button value="true">
				Abgeschlossen ({countQuestionsForCluster(cluster, "true")})
			</Radio.Button>
		</Radio.Group>
	);
}
