import { useState, useRef, useEffect } from "react";
import { Button, Steps, Breadcrumb, Form } from "antd";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from 'dayjs'

// COMPONENTS
import LoadingScreem from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";
import SelectClusters from "../../components/SelectClusters";
import AuditForm from "../../components/AuditForm";

// LOCAL COMPONENTS
// --

// CONTEXT
import { useClusters } from "../../context/Cluster";
import { useAudits, useUpdateAuditMutation } from "../../context/Audit";

const breadcrumbItems = [
	{ title: "Dashboard", href: "/" },
	{ title: "Audit Einstellungen" },
];

// MAIN
export default function AuditSettings() {
	// HOOKS
	const { audits, auditsLoading, auditsError } = useAudits();
	const { clusters, clustersLoading, clustersError } = useClusters();
	const updateAuditMutation = useUpdateAuditMutation();
	const { auditId } = useParams();
	const audit = audits && audits.find((audit) => audit._id === auditId);

	// SELECT CLUSTERS
	const [selectedClusters, setSelectedClusters] = useState([]);
	const [selectedQuestionsInCluster, setSelectedQuestionsInCluster] = useState(
		{}
	);

	// AUDIT FORM
	const [form] = Form.useForm();
	const auditFormRef = useRef();

	// STEP HANDLING
	const [current, setCurrent] = useState(0);
	const next = () => {
		setCurrent(current + 1);
	};
	const prev = () => {
		setCurrent(current - 1);
	};

	// Effect hooks
	useEffect(() => {
		if (audit && audit.auditClusters) {

			// set Clusters
			setSelectedClusters(audit.auditClusters);

			// set Audit Fields
			form.setFieldsValue({
				auditName: audit.auditName,
				auditClient: audit.auditClient._id,
				auditKeyPerson: audit.auditKeyPerson,
				auditAuditor: audit.auditAuditor,
				auditCarryOut: dayjs(audit.auditCarryOut, 'YYYY-MM-DD'),
				auditPassingThreshold: audit.auditPassingThreshold,
				auditCycle: audit.auditCycle,
			})
		} else {
			setSelectedClusters([]);
		}
	}, [audit]);

	// DISPLAY LOADING PAGE
	if (clustersLoading || auditsLoading) return <LoadingScreem />;

	// DISPLAY ERROR PAGE
	if (clustersError || auditsError) return <ErrorScreen />;

	// FORM HANDLING STEP 3
	const onFinish = async (values) => {
		// change date format from date picker
		if (values.auditCarryOut) {
			values.auditCarryOut = values.auditCarryOut.format("YYYY-MM-DD");
		}

		// set clusters format
		// ADDING ADDITIONAL FIELDS IF NEW 
		const updatedClusters = selectedClusters.map((cluster) => {
			return {
				...cluster,
				clusterQuestions: cluster.clusterQuestions.map((question) => {
					return {
						questionName: question.questionName,
						questionWeight: question.questionWeight,
						questionType: question.questionType,
						questionClientNote: question.questionClientNote ? question.questionClientNote : "",
						questionAuditorNote: question.questionAuditorNote ? question.questionAuditorNote : "",
						questionIsDone: question.questionIsDone ? question.questionIsDone : "false",
						questionToDos: question.questionToDos ? question.questionToDos : [],
					};
				}),
			};
		});

		const updatedAudit = {
			...values,
			_id: audit._id,
			auditIsDone: audit.auditIsDone ? audit.auditIsDone : "false",
			auditClusters: updatedClusters,
		};

		updateAuditMutation.mutate({ auditId: audit._id, auditData: updatedAudit });
		console.log(updatedAudit);
		// form.resetFields();
	};

	// STEPS
	const steps = [
		{
			title: "Controls und Cluster auswählen",
			content: chooseControls,
		},
		{
			title: "Allgemeine Informationen",
			content: inputInfos,
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	return (
		<>
			<Box className="backgroundBox">
				<Box>
					{/* BREADCRUMBS */}
					<Breadcrumb items={breadcrumbItems} />
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						sx={{
							marginTop: "20px",
							marginBottom: "20px",
						}}
					>
						<Box display="flex" flexDirection="column">
							<Typography variant="h4" fontWeight="bold">
								Audit Einstellungen
							</Typography>
						</Box>
					</Box>
					<Steps size="small" current={current} items={items} />
					<div style={{ paddingTop: 20 }}>{steps[current].content()}</div>
				</Box>
				<div
					style={{
						marginTop: 24,
					}}
				>
					{current < steps.length - 1 && (
						<Button
							type="primary"
							onClick={() => {
								next();
								console.log(selectedClusters);
							}}
						>
							Weiter
						</Button>
					)}
					{current === steps.length - 1 && (
						<Button
							type="primary"
							onClick={() => {
								auditFormRef.current.submit();
							}}
						>
							Abschließen
						</Button>
					)}
					{current > 0 && (
						<Button
							style={{
								margin: "0 8px",
							}}
							onClick={() => prev()}
						>
							Zurück
						</Button>
					)}
				</div>
			</Box>
		</>
	);

	function chooseControls() {
		return (
			<SelectClusters
				clusters={clusters}
				selectedClusters={selectedClusters}
				setSelectedClusters={setSelectedClusters}
				selectedQuestionsInCluster={selectedQuestionsInCluster}
				setSelectedQuestionsInCluster={setSelectedQuestionsInCluster}
			/>
		);
	}

	function inputInfos() {
		return (
			<AuditForm
				form={form}
				auditFormRef={auditFormRef}
				onFinish={onFinish}
			/>
		);
	}
}
