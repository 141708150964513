import axiosInstance from "./axiosInstance"

/*
  @desc: Get all questions for a specific cluster from the database
  @para: clusterId
*/
async function getQuestions(clusterId) {
  const response = await axiosInstance.get(`/clusters/${clusterId}/questions`);
  return response.data;
}

/*
  @desc: Create a new question for a specific cluster
  @para: c -> { clusterId, questionData }
*/
async function createQuestion(c) {
  const response = await axiosInstance.post(`/clusters/${c.clusterId}/questions`, c.questionData);
  return response.data;
}

/*
  @desc: Update a current question for a specific cluster
  @para: c -> { clusterId, questionId, questionData }
*/
async function updateQuestion(c) {
  const response = await axiosInstance.put(`/clusters/${c.clusterId}/questions/${c.questionId}`, c.questionData);
  return response.data;
}

/*
  @desc: Delete a question for a specific cluster
  @para: c -> { clusterId, questionId }
*/
async function deleteQuestion(c) {
  const response = await axiosInstance.delete(`/clusters/${c.clusterId}/questions/${c.questionId}`);
  return response.data;
}

export { getQuestions, createQuestion, updateQuestion, deleteQuestion };
