import { parse, addMonths, format } from "date-fns";

// TRANSFORM DATE IN RIGHT FORMAT
export function getRightDateFormat (date) {
    const d = parse(date, "yyyy-MM-dd", new Date());
    return format(d, "dd.MM.yyyy");
};

// GET NEXT DATE
export const getNextDate = (date, months) => {
    const d = parse(date, "yyyy-MM-dd", new Date());
    const newDate = addMonths(d, months);
    return format(newDate, "dd.MM.yyyy");
};