import { useEffect } from "react";
import { Button, Col, Drawer, Form, Input, Row, Space } from "antd";

// CONTEXT & HOOKS
import {
	useCreateClientMutation,
	useUpdateClientMutation,
} from "../../../context/Client";

// MAIN
export default function ClientDrawer({ isEditing, setIsEditing, initClientForm, onClose, open }) {
	const [form] = Form.useForm();
	const createClientMutation = useCreateClientMutation();
	const updateClientMutation = useUpdateClientMutation();

    // LOAD FIELD VALUES WHEN CALLED
    useEffect(() => {

        if(isEditing) {
            form.setFieldsValue(initClientForm)
        } else {
            form.resetFields()
        }
    }, [isEditing])

	const onSubmit = async (values) => {

		if (isEditing) {
			updateClientMutation.mutate({
				clientId: initClientForm.id,
				clientData: values,
			});
		} else {
			createClientMutation.mutate(values);
		}

		form.resetFields();
        setIsEditing(false)
		onClose();
	};

	return (
		<Drawer
			title="Neuen Kunden hinzufügen"
			width={420}
			onClose={onClose}
			open={open}
			bodyStyle={{ paddingBottom: 80 }}
			footer={
				<Space style={{ float: "right" }}>
					<Button onClick={onClose}>Abbrechen</Button>
					<Button type="primary" onClick={() => form.submit()}>
						Absenden
					</Button>
				</Space>
			}
		>
			<Form layout="vertical" form={form} onFinish={onSubmit}>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="clientName"
							label="Kundenname"
							rules={[
								{
									required: true,
									message: "Bitte geben Sie den Kundennamen ein",
								},
							]}
						>
							<Input placeholder="Bitte geben Sie den Kundennamen ein" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="clientAddress"
							label="Kundenadresse"
							rules={[
								{
									required: true,
									message: "Bitte geben Sie die Kundenadresse ein",
								},
							]}
						>
							<Input placeholder="Bitte geben Sie die Kundenadresse ein" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="clientKeyPerson"
							label="Ansprechpartner des Kunden"
							rules={[
								{
									required: true,
									message:
										"Bitte geben Sie den Namen der verantworltichen Person ein",
								},
							]}
						>
							<Input placeholder="Bitte geben Sie den Namen der verantworltichen Person ein" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
}
