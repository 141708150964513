import React from "react";
import { Checkbox, useTheme } from "@mui/material";
import { tokens } from "../theme";

// ICONS
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// MAIN
export default function CheckboxWrapper(props) {

  	// GET THEME AND COLORS
	const theme = useTheme();
	const colors = tokens(theme.palette);

  return (
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon sx={{color: colors.midgrey}} />}
      checkedIcon={<CheckBoxIcon sx={{color: colors.yellow}} />}
      sx={{
        '& .MuiSvgIcon-root': {
          width: 25,
          height: 25,
        },
      }}
      {...props}
    />
  );
}