import { useQuery } from "react-query";
import { createContext, useContext } from "react";
import { getAudits } from "../../api/auditService";
import { useUser } from "../User"

export const AuditsContext = createContext({});

export function AuditsContextProvider({ children }) {
	const { data: audits = [], isLoading, error } = useQuery("audits", getAudits);
	const { user, loading } = useUser()

	let filteredAudits = []

	if (user && audits) {
		filteredAudits = audits.filter((audit) => {
			if(user.userEmail === "revisior@axpy.de") {
				return audit.auditAuditor === user.userName
			}
			return audits
		})
	}

	return (
		<AuditsContext.Provider value={{ audits: filteredAudits, auditsLoading: isLoading, auditsError: error }}>
			{children}
		</AuditsContext.Provider>
	);
}

export function useAudits() {
    return useContext(AuditsContext);
}