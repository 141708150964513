import { useMutation, useQueryClient } from 'react-query';
import { createQuestion, updateQuestion, deleteQuestion } from '../../api/questionService'; // Pfad anpassen
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export function useCreateQuestionMutation() {
  const queryClient = useQueryClient();

  return useMutation(createQuestion, {
    onSuccess: () => {
      toast.success('Frage erfolgreich erstellt');
      queryClient.invalidateQueries('clusters');
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || 'Unerwarteter Fehler';
      toast.error(errorMessage);
    },
  });
}

export function useUpdateQuestionMutation() {
  const queryClient = useQueryClient();

  return useMutation(updateQuestion, {
    onSuccess: (data, variables) => {
      toast.success('Frage erfolgreich aktualisiert');
      queryClient.invalidateQueries('clusters')
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || 'Unerwarteter Fehler';
      toast.error(errorMessage);
    },
  });
}

export function useDeleteQuestionMutation() {
  const queryClient = useQueryClient();

  return useMutation(deleteQuestion, {
    onSuccess: () => {
      toast.success('Frage erfolgreich gelöscht');
      queryClient.invalidateQueries('clusters');
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || 'Unerwarteter Fehler';
      toast.error(errorMessage);
    },
  });
}
