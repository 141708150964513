import { Radio } from "antd";

// UTILS
import {
	calcProgressMustHavesAudit,
	calcProgressPointsAudit,
} from "../../utils/Audit";

export function ClusterFilter({audit}) {

	return (
		<Radio.Group defaultValue="a" size="large">
			<Radio.Button value="a">
				Punkte ({calcProgressPointsAudit(audit)}%)
			</Radio.Button>
			<Radio.Button value="b">
				Must Haves ({calcProgressMustHavesAudit(audit)}%)
			</Radio.Button>
		</Radio.Group>
	);
}
