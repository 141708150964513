import { useMutation, useQueryClient } from "react-query";
import { createClient, updateClient, deleteClient } from "../../api/clientService";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export function useCreateClientMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(createClient, {
        onSuccess: () => {
            toast.success("Kunde erfolgreich erstellt");
            queryClient.invalidateQueries("clients");
            navigate("/clients"); // Assuming you want to redirect to "/clients" after creating
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}

export function useUpdateClientMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(updateClient, {
        onSuccess: () => {
            toast.success("Kunde erfolgreich aktualisiert");
            queryClient.invalidateQueries("clients");
            navigate("/clients"); // Assuming you want to redirect to "/clients" after updating
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}

export function useDeleteClientMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(deleteClient, {
        onSuccess: () => {
            toast.success("Kunde erfolgreich gelöscht");
            queryClient.invalidateQueries("clients");
            navigate("/clients"); // Assuming you want to redirect to "/clients" after deleting
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}
