import { useMutation, useQueryClient } from "react-query";
import { createAudit, updateAudit, deleteAudit } from "../../api/auditService";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export function useCreateAuditMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(createAudit, {
        onSuccess: () => {
            toast.success("Audit erfolgreich erstellt");
            queryClient.invalidateQueries("audits");
            navigate("/audits");
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}

export function useUpdateAuditMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(updateAudit, {
        onSuccess: () => {
            toast.success("Audit erfolgreich aktualisiert");
            queryClient.invalidateQueries("audits");
            navigate("/audits");
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}

export function useDeleteAuditMutation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(deleteAudit, {
        onSuccess: () => {
            toast.success("Audit erfolgreich gelöscht");
            queryClient.invalidateQueries("audits");
            navigate("/audits");
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || "Unerwarteter Fehler";
            toast.error(errorMessage);
        },
    });
}
