import { Box } from "@mui/material";

// MAIN
export default function Dashboard() {

	return (
		<Box className="backgroundBox">
			
		</Box>
	)
}
