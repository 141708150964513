import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Timeline } from "antd";

// CONTEXT
import { useAudits } from "../../context/Audit";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";

function ClientHistory() {
	const { audits, auditsLoading, auditsError } = useAudits();
	const { clientId } = useParams();

	if (auditsLoading) return <LoadingScreen />;
	if (auditsError) return <ErrorScreen />;

	const filteredAudits = audits.filter(
		(audit) => audit.auditClient._id === clientId
	);

    const transformedAudits = filteredAudits.map((audit) => {
        return {
            label: audit.auditCarryOut,
            color: audit.auditIsDone === "true" ? "green" : "red",
            children: AuditCard(audit={audit})
        }
    })

	return (
		<Box className="backgroundBox">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" width="50%">
                        <Timeline items={transformedAudits} style={{width: "90%"}} />
                    </Box>


                    <Box>fdsfd</Box>

                </Box>
			</Box>
	);

    function AuditCard({audit}) {
        return (
            <Box display="flex" flexDirection="row" border="1px solid black">
                {audit.auditName}
            </Box>
        )
    }
}

export default ClientHistory;
