import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Table, Tag, Input, Breadcrumb } from "antd";

// COMPONENTS
import CellButtonNavigate from "../../components/CellButtonNavigate";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";
import TopBarButton from "../_Global/TopBarButton";
import { AuditFilter } from "../../components/Filter"

// CONTEXT
import { useAudits } from "../../context/Audit";

// ICONS
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// UTILS
import {
	countToDosForAudit,
	unifiedFilter
} from "../../utils/Audit";
import { getRightDateFormat } from "../../utils/Date";

const breadcrumbItems = [
	{ title: 'Dashboard', href: '/' },
	{ title: 'Audits' },
  ];

// MAIN
export default function Audits() {
	// HOOKS
	const { audits, auditsLoading, auditsError } = useAudits();
	const navigate = useNavigate();

	// SEARCH
	const { Search } = Input;
	const [searchTerm, setSearchTerm] = useState("");
	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	// BIG FILTER AUDIT STATUS
	const [auditStatus, setAuditStatus] = useState("a");

	// DEFINITION OF COLUMNS
	const columns = [
		{
			title: "Auditname",
			dataIndex: "auditName",
			key: "auditName",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "90%",
						height: "80%",
					}}
				>
					<CellButtonNavigate
						to={`/audits/${record.id}/clusters`}
						text={record.auditName}
					/>
				</div>
			),
			sorter: (a, b) => a.auditName.localeCompare(b.auditName),
		},
		{
			title: "Kunde",
			dataIndex: "auditClient",
			key: "auditClient",
			sorter: (a, b) => a.auditClient.localeCompare(b.auditClient),
		},
		{
			title: "Durchgeführt am",
			dataIndex: "auditCarryOut",
			key: "auditCarryOut",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						justifyContent: "left",
						alignItems: "center",
					}}
				>
					<CalendarMonthIcon
						style={{ width: "20px", height: "20px", margin: "10px" }}
					/>
					{getRightDateFormat(record.auditCarryOut)}
				</div>
			),
			sorter: (a, b) => a.auditCarryOut.localeCompare(b.auditCarryOut),
		},
		{
			title: "Auditor",
			dataIndex: "auditAuditor",
			key: "auditAuditor",
			render: (_, record) => (
				<div
					style={{
						display: "flex",
						justifyContent: "left",
						alignItems: "center",
					}}
				>
					<PersonIcon
						style={{ width: "20px", height: "20px", margin: "10px" }}
					/>
					<Typography variant="h5">{record.auditAuditor}</Typography>
				</div>
			),
			sorter: (a, b) => a.auditAuditor.localeCompare(b.auditAuditor),
		},
		{
			title: "Status",
			dataIndex: "auditIsDone",
			key: "auditIsDone",
			render: (_, record) =>
				record.auditIsDone == "true" ? (
					<Tag color="green">Abgschlossen</Tag>
				) : (
					<Tag color="red">
						Offen
					</Tag>
				),
		},
		{
			title: "ToDos",
			dataIndex: "countOpenToDos",
			key: "countOpenToDos",
			render: (_, record) =>
				record.countOpenToDos !== 0 && <Tag>{record.countOpenToDos}</Tag>,
		},
	];

	// DISPLAY LOADING PAGE
	if (auditsLoading) return <LoadingScreen />;

	// DISPLAY ERROR PAGE
	if (auditsError) return <ErrorScreen />;

	// TRANSFORM THE DATA FROM THE DATABASE FOR THE TABLE
	const transformedAudits = audits.map((audit) => ({
		id: audit._id,
		auditIsDone: audit.auditIsDone,
		auditName: audit.auditName,
		auditClient: audit.auditClient.clientName,
		auditCarryOut: audit.auditCarryOut,
		auditAuditor: audit.auditAuditor,
		countOpenToDos: countToDosForAudit(audit, "false"),
	}));

	// HANDLE SEARCH AND RADIO FILTER
	const filteredAudits = unifiedFilter("audits", transformedAudits, searchTerm, auditStatus)

	return (
		<>
			<Box className="backgroundBox">
				{/* BREADCRUMBS */}
				<Breadcrumb items={breadcrumbItems} />

				<TopBarButton
					headline="Alle Audits"
					subheadline="Hier werden alle Audits mit ihrem Status und offenen ToDos angezeigt."
					btnText="Audit anlegen"
					onClick={() => navigate("/create-audit")}
				/>

				<Box display="flex" flexDirection="column" flexGrow={1}>
					{/* TABLE */}
					<Table
						title={() => tableHeader()}
						size="small"
						columns={columns}
						dataSource={filteredAudits}
						onChange={(e) => handleSearch(e.target.value)}
					/>
				</Box>
			</Box>
		</>
	);

	function tableHeader() {
		return (
			<Box
				height="60px"
				px="10px"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<AuditFilter audits={audits} setAuditStatus={setAuditStatus} />

				<Search
					onChange={handleSearch}
					size="large"
					placeholder="Audits suchen ..."
					style={{
						width: 250,
					}}
				/>
			</Box>
		);
	}
}
